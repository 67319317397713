




import Vue from 'vue';
import { BulkIssuanceStateLabel } from '@/domain/coupon/model/bulkIssuance';

export default Vue.extend({
  name: 'BulkIssuanceStatusLabel',
  props: {
    status: {
      type: String,
    },
  },
  computed: {
    label: function (): string {
      return (
        BulkIssuanceStateLabel.find(i => i.value.toString() === this.status)
          ?.label || ''
      );
    },
  },
});
