




























































import { ContainerMixin } from '@/common/mixin/containerMixin';
import { Table, TableColumn } from 'element-ui';
import {
  DownloadCouponTargetInfo,
  DownloadCouponTargetInfoFilter,
  DownloadCouponTargetQueryFilter,
} from '@/domain/coupon/model/coupon';
import { DOWNLOAD_COUPON_TARGET_INFOS } from '@/domain/coupon/queries/query';
import { DownloadCouponTargetInfoType } from '@/domain/coupon/model/downloadCouponTargetInfoType';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { PageInfo } from '@frientrip/domain';
import {
  downloadCouponTargetInfoOptions,
  getDownloadCouponTargetInfoValueByText,
} from '@/domain/coupon/constants/downloadCouponTargetInfoOptions';
import DownloadCouponFilter from '@/domain/coupon/components/DownloadCouponFilter.vue';

interface DownloadCouponTargetInfoConnection {
  edges: { node: DownloadCouponTargetInfo; cursor: string }[];
  totalCount: number;
  pageInfo: PageInfo;
}

interface DownloadCouponTargetInfosParam {
  filter: DownloadCouponTargetInfoFilter;
  page: number;
  size: number;
}

export default ContainerMixin.extend({
  name: 'DownloadCouponList',
  components: {
    DownloadCouponFilter,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data(): {
    filter: DownloadCouponTargetInfoFilter;
    page: number;
    size: number;
    downloadCoupons: DownloadCouponTargetInfo[];
    getDownloadCouponTargetInfoOptionValue: (
      type: DownloadCouponTargetInfoType
    ) => string;
    totalCount: number;
  } {
    return {
      filter: {
        couponId: null,
        targetId: null,
        targetType: null,
        targetIdLike: null,
        titleLike: null,
        titleLikeOrTargetIdLike: null,
      },
      page: 1,
      size: 10,
      downloadCoupons: [],
      getDownloadCouponTargetInfoOptionValue: (
        type: DownloadCouponTargetInfoType
      ): string => {
        return (
          downloadCouponTargetInfoOptions.find(i => i.value.toString() == type)
            ?.text || ''
        );
      },
      totalCount: 0,
    };
  },
  methods: {
    goDownloadCouponTargetInfoDetail(id: string): void {
      this.$router.push(`/coupon/download-coupon/detail/${id}`);
    },
    refresh() {
      const query = this.$route.query as DownloadCouponTargetQueryFilter;
      console.log(query);
      this.filter.targetIdLike = query.targetIdLike ?? null;
      this.filter.titleLike = query.titleLike ?? null;
      this.filter.titleLikeOrTargetIdLike =
        query.titleLikeOrTargetIdLike ?? null;
      this.$apollo.queries.downloadCoupons.refresh();
    },
    moveRegisterPage() {
      this.$router.push(`/coupon/download-coupon/create`);
    },
  },
  apollo: {
    downloadCoupons: {
      query: DOWNLOAD_COUPON_TARGET_INFOS,
      variables(): DownloadCouponTargetInfosParam {
        return {
          filter: this.filter,
          page: this.page,
          size: this.size,
        };
      },
      error(e: ApolloError): void {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{
          coupon: {
            downloadCouponTargetInfos: DownloadCouponTargetInfoConnection;
          };
        }>
      ) {
        this.totalCount =
          result.data.coupon.downloadCouponTargetInfos.totalCount;
      },
      update: data => {
        return data.coupon.downloadCouponTargetInfos.edges.map(
          (edge: { node: DownloadCouponTargetInfo; cursor: string }) => {
            return edge.node;
          }
        );
      },
    },
  },
});
