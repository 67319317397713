import { CommonState, PageInfo } from '@frientrip/domain';
import { CouponPolicy, CouponPolicyReferenceType } from './CouponPolicy';
import { FixedTermInput } from '@/domain/notificationMessage/model/notificationMessage';
import { DownloadCouponTargetInfoType } from '@/domain/coupon/model/downloadCouponTargetInfoType';
import { DownloadCouponButtonDisplayPositionType } from '@/domain/coupon/model/DownloadCouponButtonDisplayPositionType';
import { DownloadCouponButtonInfoParam } from '@/domain/coupon/model/DownloadCouponButtonInfoParam';
import { Dictionary } from 'vue-router/types/router';
enum CalculatingType {
  PERCENT = 'PERCENT',
  PRICE = 'PRICE',
}

export interface CalculatingElement {
  calculatingType: CalculatingType;
  calculatingValue: number;
}

interface FixedTerm {
  startedAt: number;
  endedAt: number;
}

export interface UsableTermElement {
  usableDays: number;
  usableTerm: FixedTerm;
}

enum CouponApplyType {
  CART = 'CART',
  OPTION = 'OPTION',
}

export enum CouponIssueType {
  DOWNLOAD = 'DOWNLOAD',
  KEYWORD = 'KEYWORD',
  SERIAL = 'SERIAL',
}

export interface CouponEdge {
  node: CouponAPIResponse;
  cursor: string;
}

export interface CouponConnection {
  edges: CouponEdge[];
  totalCount: number;
  pageInfo: PageInfo;
}

export interface CouponAPIResponse {
  id: number;
  applyType: CouponApplyType;
  calculatingElement: CalculatingElement;
  catchphrase: string;
  description: string;
  hostBurdenRatio: number;
  issuedCount: number;
  issuableCountPerUser: number;
  issuableTerm: FixedTerm;
  issueType: CouponIssueType;
  keywords: string[];
  maxBenefit: number;
  maxIssuableCount: number;
  minApplicationAmount: number;
  policies: CouponPolicy[];
  title: string;
  usableTermElement: UsableTermElement;
  usedCount: number;
  useType: CouponUseType;
  duplicateUseAllowed: boolean;
  exposed: boolean;
}

export interface CouponForm {
  id?: number | string;
  applyType: string;
  calculatingElement: {
    calculatingType: string;
    calculatingValue: number | string;
  };
  catchphrase: string;
  description: string;
  hostBurdenRatio: number | string;
  issuableCountPerUser: number | string;
  issuableTerm: {
    startedAt: number | string;
    endedAt: number | string;
  };
  issueType: string;
  maxBenefit: number | string;
  maxIssuableCount: number | string;
  minApplicationAmount: number | string;
  title: string;
  usableTermElement: {
    usableDays?: number | null;
    usableTerm?: {
      startedAt: number;
      endedAt: number;
    } | null;
  };
  issuedCount: number;
  keywords?: string[];
  policies?: CouponPolicy[];
  usedCount?: number;
  useType: CouponUseType;
  duplicateUseAllowed: boolean;
  exposed: boolean;
}

export interface CreateCouponRequestParam {
  id?: number | string;
  applyType: string;
  calculatingElement: {
    calculatingType: string;
    calculatingValue: number | string;
  };
  catchphrase: string;
  description: string;
  hostBurdenRatio: number;
  issuableCountPerUser: number;
  issuableTerm: {
    startedAt: number;
    endedAt: number;
  };
  issueType: string;
  maxBenefit: number;
  maxIssuableCount: number;
  minApplicationAmount: number;
  title: string;
  usableTermElement: {
    usableDays?: number | null;
    usableTerm?: {
      startedAt: number;
      endedAt: number;
    } | null;
  };
  keywords?: string[];
  policies?: {
    allowed: boolean;
    reference: {
      id: number;
      type: CouponPolicyReferenceType;
    };
  }[];
}

export interface UpdateCouponRequestParam {
  couponId: number;
  applyType?: string;
  calculatingElement?: {
    calculatingType: string;
    calculatingValue: number | string;
  };
  catchphrase?: string;
  description?: string;
  hostBurdenRatio?: number;
  issuableCountPerUser?: number;
  issuableTerm?: {
    startedAt: number;
    endedAt: number;
  };
  issueType?: string;
  maxBenefit?: number;
  maxIssuableCount?: number;
  minApplicationAmount?: number;
  title?: string;
  usableTermElement?: {
    usableDays?: number | null;
    usableTerm?: {
      startedAt: number;
      endedAt: number;
    } | null;
  };
  keywords?: string[];
  policies?: {
    allowed: boolean;
    reference: {
      id: number;
      type: CouponPolicyReferenceType;
    };
  }[];
}

export interface CouponsRequestParam {
  page?: number;
  size?: number;
  filter?: CouponFilterInput;
}

export interface CouponFilterInput {
  issuableTerm?: FixedTermInput;
  title?: string;
}

export interface CouponsAndTotalCount {
  coupons: CouponAPIResponse[];
  totalCount: number;
}

export interface DownloadCouponTargetInfo {
  id: number;
  description: string;
  downloadCouponButtonInfos: DownloadCouponButtonInfo[];
  downloadableCoupons: CouponAPIResponse[];
  downloadableCouponInfos: DownloadableCouponInfo[];
  status: CommonState;
  targetId: string;
  targetType: DownloadCouponTargetInfoType;
  title: string;
  updatedAt?: Date;
  createdAt?: Date;
}

export interface DownloadCouponButtonInfo {
  id: number | null;
  displayPosition: DownloadCouponButtonDisplayPositionType;
  buttonImage: Content;
  status: CommonState;
  imageName?: string;
}

interface Content {
  id: string;
  uri: string;
}

export interface DownloadCouponTargetInfosInput {
  id: string | null;
  description: string | null;
  downloadCouponButtonInfos: DownloadCouponButtonInfoParam[];
  downloadableCouponInfos: DownloadableCouponInfoParam[];
  status: CommonState;
  targetId: string;
  targetType: DownloadCouponTargetInfoType;
  title: string;
}

export interface DownloadableCouponInfoParam {
  couponId: string;
  id: string | null;
  status: CommonState | null;
}

export interface DownloadableCouponInfo {
  coupon: CouponAPIResponse;
  id: string | null;
  status: CommonState | null;
}

export interface DownloadCouponTargetInfoFilter {
  couponId: string | null;
  targetId: string | null;
  targetType: DownloadCouponTargetInfoType | null;
  targetIdLike: string | null;
  titleLike: string | null;
  titleLikeOrTargetIdLike: string | null;
}

export interface DownloadCouponTargetQueryFilter
  extends Dictionary<string | string[]> {
  targetType: string;
  targetId: string;
  couponId: string;
  titleLike: string;
  targetIdLike: string;
  titleLikeOrTargetIdLike: string;
}

export enum CouponUseType {
  NORMAL = 'NORMAL',
  ADDITIONAL = 'ADDITIONAL',
}
