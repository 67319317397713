import { render, staticRenderFns } from "./MagazineSearchInput.vue?vue&type=template&id=582f0142&scoped=true&"
import script from "./MagazineSearchInput.vue?vue&type=script&lang=ts&"
export * from "./MagazineSearchInput.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "582f0142",
  null
  
)

export default component.exports