

































import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import Vue, { VueConstructor } from 'vue';
import gql from 'graphql-tag';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { Collection } from '@/domain/collection/model/collection';

interface CollectionIdAndTitle {
  id: string;
  title: string;
}

const GET_COLLECTION = gql`
  query Collection($id: ID!) {
    collection {
      collection(id: $id) {
        id
        title
      }
    }
  }
`;

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLFormElement } }>
).extend({
  name: 'CollectionAndMagazineSearchInput',
  components: {
    VueBootstrapTypeahead,
  },
  model: {
    event: 'select',
  },
  props: {
    searchType: {
      type: String,
    },
    value: {
      type: String,
    },
  },
  data(): {
    searchCollectionId: string;
    collectionId: string;
    collections: CollectionIdAndTitle[];
    title: string;
    collection: Collection | null;
    collectionTitle: string;
  } {
    return {
      searchCollectionId: '',
      collectionId: '',
      collections: [],
      title: '',
      collection: null,
      collectionTitle: '',
    };
  },
  watch: {
    value(newValue: string) {
      if (newValue === '') {
        this.$refs.collectionAndMagazineSearchInput.inputValue = newValue;
      }
      this.searchCollectionId = newValue;
    },
  },
  methods: {
    async getCollection(): Promise<void> {
      await this.$apollo.queries.collection.refetch();
    },
    selectCollection(collection: Collection): void {
      this.$emit('select', {
        targetId: this.collectionId,
        targetTitle: this.title,
      });
    },
  },
  apollo: {
    collection: {
      query: GET_COLLECTION,
      variables(): { id: number } {
        return {
          id: Number(this.searchCollectionId),
        };
      },
      result(
        result: ApolloQueryResult<{ collection: { collection: Collection } }>
      ): void {
        if (result.data?.collection.collection) {
          this.collectionId = result.data.collection.collection.id;
          this.title = result.data.collection.collection.title;
          this.collections[0] = {
            id: result.data.collection.collection.id,
            title: result.data.collection.collection.title,
          };
        } else {
          this.collectionId = '';
          this.title = '';
          this.collections = [];
        }
        this.$emit('select', {
          targetId: this.collectionId,
          targetTitle: this.title,
        });
      },
      error(error: ApolloError) {
        console.log(error);
      },
      skip(): boolean {
        return this.searchCollectionId == '';
      },
    },
  },
});
