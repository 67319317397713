






















































































































import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { COUPON_LIST_QUERY } from '../queries/query';
import { Table, TableColumn } from 'element-ui';
import Instant from '@/components/Labels/Instant.vue';

import {
  CouponConnection,
  CouponEdge,
  CalculatingElement,
  CouponAPIResponse,
} from '../model/coupon';

import Vue from 'vue';
import CouponFilter from '../components/CouponFilter.vue';

interface CouponsRequestParam {
  page?: number;
  size?: number;
  filter?: any; // TODO: 타입 지정 필요함
}

interface IssuableTerm {
  startedAt: number;
  endedAt: number;
}

interface CouponFilterType {
  issuableTerm: IssuableTerm | null;
  title: string | null;
}
export default Vue.extend({
  name: 'CouponList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Instant,
    CouponFilter,
  },
  data() {
    return {
      coupon: { coupons: { edges: [] as CouponEdge[] } },
      title: '쿠폰 목록',
      page: this.$route.query.page || 1,
      size: this.$route.query.size || 10,
      totalRows: 0,
      couponTitle: '',
      issuableTerm: {
        startedAt: '',
        endedAt: '',
      },
      filter: {
        issuableTerm: null,
        title: null,
      } as CouponFilterType,
    };
  },
  computed: {
    coupons(): CouponAPIResponse[] {
      if (this.coupon.coupons.edges) {
        return this.coupon.coupons.edges.map(edge => edge.node);
      } else {
        return [];
      }
    },
  },
  methods: {
    addFilter(filter: CouponFilterType): void {
      this.filter = {
        issuableTerm: filter.issuableTerm,
        title: filter.title === '' ? null : filter.title,
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pageClick(bvEvent: any, page: number): void {
      console.log('page clicked!', page);
      if (this.$route.path !== `/coupon/list?page=${page}&size=${this.size}`) {
        this.$router.push(`/coupon/list?page=${page}&size=${this.size}`);
      }
    },
    saledPrice(calculatingElement: CalculatingElement): string {
      const { calculatingType, calculatingValue } = calculatingElement;
      if (calculatingType === 'PERCENT') {
        return calculatingValue.toString() + '%';
      } else if (calculatingType === 'PRICE') {
        return calculatingValue.toLocaleString() + '원';
      } else {
        return '';
      }
    },
    issueTypeColor(issueType: string): string {
      if (issueType === 'DOWNLOAD') {
        return 'success';
      } else if (issueType === 'KEYWORD') {
        return 'warning';
      } else if (issueType === 'SERIAL') {
        return 'info';
      } else {
        return 'default';
      }
    },
    maxBenefitLabel(maxBenefit: number): string {
      if (!maxBenefit) {
        return '없음';
      }
      return maxBenefit.toLocaleString();
    },
  },
  apollo: {
    coupon: {
      query: COUPON_LIST_QUERY,
      variables(): CouponsRequestParam {
        return {
          page: Number(this.page),
          size: Number(this.size),
          filter: this.filter,
        };
      },
      error(e: ApolloError): void {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{ coupon: { coupons: CouponConnection } }>
      ): void {
        this.totalRows = result.data.coupon.coupons.totalCount;
      },
      update: data => data.coupon,
    },
  },
});
