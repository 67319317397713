

































import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import Vue, { VueConstructor } from 'vue';
import axios from 'axios';
import { getLegacyApiUrl } from '@/env';

interface Magazine {
  id: string;
  title: string;
}

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLFormElement } }>
).extend({
  name: 'MagazineSearchInput',
  components: {
    VueBootstrapTypeahead,
  },
  model: {
    event: 'select',
  },
  props: {
    value: {
      type: String,
    },
    id: {
      type: Number,
    },
  },
  data(): {
    searchMagazineId: string;
    magazineId: string;
    magazines: Magazine[];
    title: string;
  } {
    return {
      searchMagazineId: this.value !== '' ? this.value : '',
      magazineId: '',
      magazines: [],
      title: '',
    };
  },
  watch: {
    value(newValue: string) {
      if (newValue === '') {
        this.$refs.magazineSearchInput.inputValue = newValue;
      }
      this.searchMagazineId = newValue;
    },
  },
  beforeMount() {
    if (this.id) {
      this.getMagazines();
    }
  },
  methods: {
    async getMagazines() {
      try {
        if (this.searchMagazineId === '') {
          return;
        }

        const result = await axios({
          url: `${getLegacyApiUrl()}/Products/v5/magazines/${
            this.searchMagazineId
          }`,
          method: 'GET',
        });

        if (result.status !== 200) {
          this.magazines[0] = {
            id: '',
            title: '',
          };
        }

        this.magazineId = result.data.id.toString();
        this.title = result.data.title;
        this.searchMagazineId = result.data.id.toString();

        this.magazines[0] = {
          id: result.data.id,
          title: result.data.title,
        };

        this.$emit('select', {
          targetId: this.searchMagazineId,
          targetTitle: this.title,
        });
      } catch (e: any) {
        this.magazineId = '';
        this.title = '';
        console.error(e.message);
      }
    },
  },
});
