




























import Vue from 'vue';

export default Vue.extend({
  name: 'CouponFilter',
  data() {
    return {
      issuableTerm: {
        startedAt: 0,
        endedAt: 0,
      },
      title: '',
    };
  },
  methods: {
    addFilter(): void {
      const filter = {
        issuableTerm: this.issuableTerm,
        title: this.title,
      };
      this.$emit('addFilter', filter);
    },
  },
});
