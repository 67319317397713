export enum CouponPolicyReferenceType {
  CATEGORY = 'CATEGORY',
  PRODUCT = 'PRODUCT',
  HOST = 'HOST',
}

export enum CouponPolicyState {
  REGISTERED = 'REGISTERED',
  UNREGISTERED = 'UNREGISTERED',
  DELETED = 'DELETED',
}

export interface CouponPolicyReference {
  id: string;
  type: CouponPolicyReferenceType;
  displayName?: string;
}

export interface CouponPolicyRequestParam {
  couponId?: number;
  reference: CouponPolicyReference;
}

export interface CreateCouponPolicyRequestParam
  extends CouponPolicyRequestParam {
  allowed: boolean;
}

export interface CouponPolicy {
  id?: number;
  allowed: boolean;
  reference: CouponPolicyReference;
  status: CouponPolicyState;
}
