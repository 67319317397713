


















































































































import Vue from 'vue';
import {
  CouponPolicy,
  CouponPolicyReference,
  CouponPolicyReferenceType,
  CouponPolicyState,
} from '../model/CouponPolicy';
import CouponPolicyReferenceSearch from '@/domain/coupon/components/CouponPolicyReferenceSearch.vue';

export default Vue.extend({
  name: 'CouponPolicyList',
  components: {
    CouponPolicyReferenceSearch,
    // HostSearchList,
  },
  props: {
    value: {
      type: Array,
    },
    allowed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showHostsSearchModal: false,
      showProductsSearchModal: false,
      showCategoriesSearchModal: false,
      policies: this.value
        ? (this.value as CouponPolicy[])
        : ([] as CouponPolicy[]),
      referenceTypes: [
        {
          text: '호스트',
          type: CouponPolicyReferenceType.HOST,
        },
        {
          text: '상품',
          type: CouponPolicyReferenceType.PRODUCT,
        },
        {
          text: '카테고리',
          type: CouponPolicyReferenceType.CATEGORY,
        },
      ],
      selectedPolicies: [] as CouponPolicy[],
      showErrorMessageTime: 0,
      errorMessage: '',
      couponPolicies: [] as CouponPolicy[],
    };
  },
  computed: {
    policyListType(): string {
      if (this.allowed) {
        return '화이트 리스트';
      } else {
        return '블랙 리스트';
      }
    },
  },
  created() {
    this.couponPolicies = this.value as CouponPolicy[];
  },
  methods: {
    showErrorMessage(message: string): void {
      this.showErrorMessageTime = 5;
      this.errorMessage = message.replace('GraphQL error:', '');
    },
    countDownChanged(showErrorMessageTime: number): void {
      this.showErrorMessageTime = showErrorMessageTime;
    },
    showModal(couponPolicyReferenceType: CouponPolicyReferenceType): void {
      if (couponPolicyReferenceType === CouponPolicyReferenceType.HOST) {
        this.showHostsSearchModal = true;
      }
      if (couponPolicyReferenceType === CouponPolicyReferenceType.CATEGORY) {
        this.showCategoriesSearchModal = true;
      }
      if (couponPolicyReferenceType === CouponPolicyReferenceType.PRODUCT) {
        this.showProductsSearchModal = true;
      }
    },
    badgeTypeForPolicyReference(type: string): string {
      if (type === 'HOST') {
        return 'primary';
      } else if (type === 'CATEGORY') {
        return 'warning';
      } else if (type === 'PRODUCT') {
        return 'success';
      }
      return 'default';
    },
    isDeleted(couponPolicy: CouponPolicy): boolean {
      return couponPolicy.status == CouponPolicyState.DELETED;
    },
    isEqualReference(
      reference: CouponPolicyReference,
      referenceToBeCompared: CouponPolicyReference
    ): boolean {
      if (
        reference.id !== referenceToBeCompared.id ||
        reference.type !== referenceToBeCompared.type
      ) {
        return false;
      }
      return true;
    },
    async deleteCouponPolicy(
      policyReference: CouponPolicyReference
    ): Promise<void> {
      const policy = this.policies.find(i => i.reference === policyReference);

      if (policy) {
        policy.status = CouponPolicyState.DELETED;
      }

      this.$emit('changePolicies', this.policies, this.allowed);
    },

    async unDeleteCouponPolicy(
      policyReference: CouponPolicyReference
    ): Promise<void> {
      const policy = this.policies.find(i => i.reference === policyReference);

      if (policy) {
        policy.status = CouponPolicyState.UNREGISTERED;
      }

      this.$emit('changePolicies', this.policies, this.allowed);
    },

    async addCouponPolicy(couponPolicies: CouponPolicy[]): Promise<void> {
      this.policies.push(...couponPolicies);
      this.$emit('changePolicies', this.policies, this.allowed);
      this.showHostsSearchModal = false;
      this.showProductsSearchModal = false;
      this.showCategoriesSearchModal = false;
    },
    closeModal(): void {
      this.showHostsSearchModal = false;
      this.showProductsSearchModal = false;
      this.showCategoriesSearchModal = false;
    },
  },
});
