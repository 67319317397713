import gql from 'graphql-tag';

export const GET_PRODUCTS_FOR_COUPON_POLICY_REF = gql`
  query getProductsForCouponPolicyRef($size: Int, $productIds: [String!]) {
    product {
      products(size: $size, filter: { ids: $productIds }) {
        edges {
          node {
            id
            title
          }
        }
      }
    }
  }
`;

export const GET_CATEGORIES_FOR_COUPON_POLICY_REF = gql`
  query getCategoriesForCouponPolicyRef($size: Int, $categoryIds: [String!]) {
    category {
      displays(size: $size, filter: { ids: $categoryIds }) {
        edges {
          node {
            id
            label {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_HOSTS_FOR_COUPON_POLICY_REF = gql`
  query getHostsForCouponPolicyRef($size: Int, $hostIds: [String!]) {
    hosts(size: $size, filter: { ids: $hostIds }) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
