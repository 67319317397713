var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('content-card',{attrs:{"title":_vm.title,"subtitle":_vm.totalRows.toString(),"notification":"쿠폰 타이틀을 클릭하면 쿠폰 상세 정보를 볼 수 있습니다."},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('b-pagination',{staticClass:"mt-3",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.size,"align":"center","first-number":"","last-number":""},on:{"page-click":_vm.pageClick},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('b-card-footer',[_c('frip-button',{staticClass:"float-right",attrs:{"type":"primary","to":"./create"}},[_vm._v("쿠폰 등록")])],1)]},proxy:true}])},[_c('coupon-filter',{staticClass:"mb-4",on:{"addFilter":_vm.addFilter}}),_c('el-table',{ref:"table",staticClass:"table table-responsive table-flush table-striped",attrs:{"header-row-class-name":"thead-light","data":_vm.coupons}},[_c('el-table-column',{attrs:{"label":"id","prop":"id","min-width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"타이틀","prop":"title","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-link',{attrs:{"to":("./list/" + (row.id))}},[_vm._v(_vm._s(row.title))])]}}])}),_c('el-table-column',{attrs:{"label":"할인액","prop":"calculatingValue"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.saledPrice(row.calculatingElement))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"사용된 횟수","prop":"usedCount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.usedCount)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"발급 횟수","prop":"usedCount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.issuedCount)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"최대 할인 금액","prop":"maxBenefit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.maxBenefitLabel(row.maxBenefit))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"발급 가능 기간","prop":"calculatingValue","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('instant',{attrs:{"at":row.issuableTerm.startedAt,"short":""}}),_c('span',[_vm._v(" ~ ")]),_c('instant',{attrs:{"at":row.issuableTerm.endedAt,"short":""}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"최대 발급 가능 횟수","prop":"maxIssuableCount","width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.maxIssuableCount)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"유효기간","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.usableTermElement.usableTerm ? _vm.$moment(row.usableTermElement.usableTerm.startedAt).format( 'YYYY-MM-DD' ) + ' ~ ' + _vm.$moment(row.usableTermElement.usableTerm.endedAt).format( 'YYYY-MM-DD' ) : '')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"발급유형","prop":"issueType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('frip-badge',{attrs:{"type":_vm.issueTypeColor(row.issueType)}},[_vm._v(_vm._s(row.issueType))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }