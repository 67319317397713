export enum DownloadCouponButtonDisplayPositionType {
  UP = 'UP',
  DOWN = 'DOWN',
}

export const DownloadCouponButtonDisplayPositionTypeLabel: {
  value: DownloadCouponButtonDisplayPositionType;
  label: string;
}[] = [
  {
    value: DownloadCouponButtonDisplayPositionType.UP,
    label: '상단',
  },
  {
    value: DownloadCouponButtonDisplayPositionType.DOWN,
    label: '하단',
  },
];
