import { DownloadCouponTargetInfoType } from '@/domain/coupon/model/downloadCouponTargetInfoType';

export const downloadCouponTargetInfoOptions: {
  text: string;
  value: DownloadCouponTargetInfoType;
}[] = [
  {
    text: '상품',
    value: DownloadCouponTargetInfoType.Product,
  },
  {
    text: '구 매거진(웹)',
    value: DownloadCouponTargetInfoType.Magazine,
  },
  {
    text: '테마 기획전',
    value: DownloadCouponTargetInfoType.Collection,
  },
  {
    text: '매거진',
    value: DownloadCouponTargetInfoType.FripMagazine,
  },
];

export const getDownloadCouponTargetInfoOptionTextByValue = (
  type: DownloadCouponTargetInfoType
): string => {
  return (
    downloadCouponTargetInfoOptions.find(i => i.value === type)?.text || ''
  );
};

export const getDownloadCouponTargetInfoValueByText = (
  text: string
): DownloadCouponTargetInfoType | null => {
  return (
    downloadCouponTargetInfoOptions.find(i => i.text === text)?.value || null
  );
};
