<template>
  <div :class="paddingClass">
    <validation-provider
      :rules="rules"
      :name="name"
      v-bind="$attrs"
      v-slot="{ errors, valid, invalid, validated }"
    >
      <div class="w-100">
        <b-form-group
          :label-sr-only="!label"
          :label-cols-md="labelColsMd"
          :label-cols-lg="labelColsLg"
          :content-cols-md="contentColsMd"
          :content-cols-lg="contentColsLg"
          :label="label"
        >
          <template #label>
            <span
              v-if="required"
              :class="['text-danger', 'position-absolute', 'ml-2']"
              >*</span
            >
            <span class="pl-3" style="font-size: 0.9rem">{{ label }}</span>
            <span class="text-gray pl-2" v-if="question"
              ><icon
                name="iconHelp"
                width="20"
                v-b-tooltip.hover.bottom
                :title="question"
              ></icon
            ></span>
          </template>
          <div
            :class="[
              'input-group',
              { focused: focused },
              { 'input-group-alternative': alternative },
              { 'has-label': label || $slots.label },
              inputGroupClasses,
            ]"
          >
            <div
              v-if="prependIcon || $slots.prepend"
              class="input-group-prepend"
            >
              <span
                :class="[
                  'input-group-text',
                  { 'is-invalid': invalid && validated },
                ]"
              >
                <slot name="prepend">
                  <small><i :class="prependIcon"></i></small>
                </slot>
              </span>
            </div>
            <slot v-bind="slotData">
              <input
                :value="value"
                :type="type"
                v-on="listeners"
                v-bind="$attrs"
                v-onlyNumber
                :valid="valid"
                :disabled="disabled"
                :required="required"
                class="form-control"
                :class="[
                  { 'is-valid': valid && validated && successMessage },
                  { 'is-invalid': invalid && validated },
                  inputClasses,
                ]"
              />
            </slot>

            <div class="input-group-append">
              <span
                :class="[
                  'input-group-text',
                  { 'append-disabled': disabled },
                  { 'is-invalid': invalid && validated },
                ]"
              >
                <slot name="append">
                  <small> {{ appendIcon }} </small></slot
                >
              </span>
            </div>
            <slot name="infoBlock"></slot>
          </div>
          <slot name="success">
            <div
              class="valid-feedback"
              v-if="valid && validated && successMessage"
            >
              {{ successMessage }}
            </div>
          </slot>
          <slot name="error">
            <div
              v-if="errors[0]"
              class="invalid-feedback"
              style="display: block"
            >
              <!-- custom error message 추가 -->
              {{ invalidMessage !== '' ? invalidMessage : errors[0] }}
            </div>
          </slot>
          <div class="text-muted m-2" style="font-size: 0.9rem" v-if="caption">
            {{ caption }}
          </div>
        </b-form-group>
      </div>
    </validation-provider>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'percent-input',
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      description: 'Whether input is required (adds an asterix *)',
    },
    group: {
      type: Boolean,
      description:
        'Whether input is an input group (manual override in special cases)',
    },
    alternative: {
      type: Boolean,
      description: 'Whether input is of alternative layout',
    },
    label: {
      type: String,
      description: 'Input label (text before input)',
    },
    error: {
      type: String,
      description: 'Input error (below input)',
    },
    successMessage: {
      type: String,
      description: 'Input success message',
      default: '',
    },
    labelClasses: {
      type: String,
      description: 'Input label css classes',
      default: 'form-control-label',
    },
    inputClasses: {
      type: String,
      description: 'Input css classes',
    },
    inputGroupClasses: {
      type: String,
      default: 'w-100',
      description: 'Input group css classes',
    },
    value: {
      type: [String, Number],
      description: 'Input value',
    },
    type: {
      type: String,
      description: 'Input type',
      default: 'text',
    },
    appendIcon: {
      type: String,
      description: 'Append icon (right)',
      default: '%',
    },
    prependIcon: {
      type: String,
      description: 'Prepend icon (left)',
    },
    rules: {
      type: [String, Array, Object],
      description: 'Vee validate validation rules',
      default: '',
    },
    name: {
      type: String,
      description: 'Input name (used for validation)',
      default: '',
    },
    invalidMessage: {
      type: String,
      description: 'Custom InValid Message',
      default: '',
    },
    labelColsMd: {
      type: String,
      description: '',
      default: '4',
    },
    labelColsLg: {
      type: String,
      description: '',
      default: '2',
    },
    contentColsMd: {
      type: String,
      description: '',
      default: '8',
    },
    contentColsLg: {
      type: String,
      description: '',
      default: '6',
    },
    question: {
      type: String,
      description: 'additional question',
    },
    caption: {
      type: String,
    },
    block: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur,
        change: this.onChange,
      };
    },
    slotData() {
      return {
        focused: this.focused,
        error: this.error,
        ...this.listeners,
      };
    },
    paddingClass() {
      if (this.block) {
        return 'pt-4 pb-2 px-3';
      } else {
        return 'pb-2';
      }
    },
  },
  methods: {
    updateValue(evt) {
      let value = evt.target.value;
      this.$emit('input', value);
    },
    onChange(evt) {
      let value = evt.target.value;
      const decimal = value.split('.');
      if (decimal.length > 1 && decimal[1].length > 2) {
        value = decimal[0].concat('.', decimal[1].slice(0, 2));
      }
      value = value.replace(/[^0-9|.]/g, '');
      this.$emit('input', value);
    },
    onFocus(evt) {
      this.focused = true;
      this.$emit('focus', evt);
    },
    onBlur(evt) {
      this.focused = false;
      this.$emit('blur', evt);
    },
  },
});
</script>

<style scoped>
.input-group > .form-control:not(:last-child) {
  border-right: 0 !important;
}
.is-invalid {
  border-color: #de1c22 !important;
}
/* .append-disabled {
  background-color: #dddddd;
  opacity: 1;
} */
</style>
