import { gql } from 'apollo-boost';

export const CREATE_COUPON_MUTATION = gql`
  mutation createCoupon($param: CreateCouponRequestInput!) {
    createCoupon(param: $param) {
      id
    }
  }
`;

export const UPDATE_COUPON_MUTATION = gql`
  mutation updateCoupon($param: UpdateCouponRequestInput!) {
    updateCoupon(param: $param) {
      id
    }
  }
`;

export const DELETE_COUPON_MUTATION = gql`
  mutation deleteCoupon($param: DeleteCouponRequestInput!) {
    deleteCoupon(param: $param) {
      id
    }
  }
`;

export const CREATE_COUPON_KEYWORD_MUTATION = gql`
  mutation createCouponKeyword($param: CreateCouponKeywordRequestInput!) {
    createCouponKeyword(param: $param) {
      keywords
    }
  }
`;

export const DELETE_COUPON_KEYWORD_MUTATION = gql`
  mutation deleteCouponKeyword($param: DeleteCouponKeywordRequestInput!) {
    deleteCouponKeyword(param: $param) {
      keywords
    }
  }
`;

export const CREATE_COUPON_POLICY_MUTATION = gql`
  mutation createCouponPolicy($param: CreateCouponPolicyRequestInput!) {
    createCouponPolicy(param: $param) {
      id
      policies {
        id
        allowed
      }
    }
  }
`;

export const DELETE_COUPON_POLICY_MUTATION = gql`
  mutation deleteCouponPolicy($param: DeleteCouponPolicyRequestInput!) {
    deleteCouponPolicy(param: $param) {
      id
      policies {
        id
        allowed
      }
    }
  }
`;

export const DELETE_ISSUED_COUPON = gql`
  mutation deleteIssuedCoupon($param: DeleteIssuedCouponRequestInput!) {
    deleteIssuedCoupon(param: $param) {
      id
    }
  }
`;

export const WITHDRAW_ISSUED_COUPON = gql`
  mutation withdrawIssuedCoupon($param: WithdrawIssuedCouponRequestInput!) {
    withdrawIssuedCoupon(param: $param) {
      id
    }
  }
`;

export const WITHDRAW_ALL_ISSUED_COUPON = gql`
  mutation withdrawAllIssuedCoupon(
    $param: WithdrawAllIssuedCouponRequestInput!
  ) {
    withdrawAllIssuedCoupon(param: $param) {
      totalCount
      numberOfSuccess
      numberOfFailure
    }
  }
`;

export const ISSUE_COUPON_BY_ID_WITH_USER_IDS = gql`
  mutation issueCouponByIdWithUserIds($param: CouponBulkIssuanceParamInput!) {
    issueCouponByIdWithUserIds(param: $param) {
      id
      coupon {
        id
      }
      requestedUserCount
      status
      detailStatus
    }
  }
`;

export const CREATE_DOWNLOAD_COUPON_BUTTON_INFOS = gql`
  mutation saveDownloadCouponTargetInfo(
    $param: DownloadCouponTargetInfoParamInput!
  ) {
    saveDownloadCouponTargetInfo(param: $param) {
      id
    }
  }
`;
