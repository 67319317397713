
















































































import { ContainerMixin } from '@/common/mixin/containerMixin';
import { Table, TableColumn } from 'element-ui';
import { FixedTermInput } from '@/domain/notificationMessage/model/notificationMessage';
import {
  CouponAPIResponse,
  CouponIssueType,
  DownloadableCouponInfo,
} from '@/domain/coupon/model/coupon';
import { CouponService } from '@/domain/coupon/service/CouponService';
import { apolloClient } from '@/apolloClient';

interface CouponsRequestParam {
  page?: number;
  size?: number;
  filter?: CouponFilterInput;
}

interface CouponFilterInput {
  issuableTerm?: FixedTermInput;
  title?: string;
  ids?: string[];
  issueType?: CouponIssueType;
}

const couponService = new CouponService(apolloClient);

export default ContainerMixin.extend({
  name: 'CouponSearch',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    downloadableCoupons: {
      type: Array,
      default: () => [],
    },
  },
  data(): {
    page: number;
    size: number;
    filter: CouponFilterInput;
    totalCount: number;
    searchKeyword: string;
    coupons: CouponAPIResponse[];
    checkedCouponList: DownloadableCouponInfo[];
    allChecked: boolean;
  } {
    return {
      page: 1,
      size: 10,
      filter: {},
      totalCount: 0,
      searchKeyword: '',
      coupons: [],
      checkedCouponList: [],
      allChecked: false,
    };
  },
  methods: {
    closeModal(): void {
      this.$emit('close');
    },
    async addFilter(): Promise<void> {
      const ids = this.searchKeyword.split(',').map(id => id.trim());
      this.filter = {
        ids: ids,
        issueType: CouponIssueType.DOWNLOAD,
      };
      await this.getCouponList();
    },
    async pageClick(bvEvent: any, page: number): Promise<void> {
      this.page = page;
      await this.getCouponList();
    },
    async getCouponList(): Promise<void> {
      this.allChecked = false;
      this.checkedCouponList = [];
      const couponRequestParam: CouponsRequestParam = {
        page: Number(this.page),
        size: Number(this.size),
        filter: this.filter,
      };
      const couponsAndTotalCount = await couponService.getCoupon(
        couponRequestParam
      );

      this.coupons = couponsAndTotalCount.coupons.map(
        (coupon: CouponAPIResponse) => {
          const downloadableCoupon = this.downloadableCoupons.find(
            (downloadableCoupon: any) =>
              downloadableCoupon.coupon.id === coupon.id.toString()
          );

          if (downloadableCoupon) {
            this.checkedCouponList.push(
              downloadableCoupon as DownloadableCouponInfo
            );
          }

          return {
            ...coupon,
            checked: !!downloadableCoupon,
            disabled: !!downloadableCoupon,
          };
        }
      );
      this.totalCount = couponsAndTotalCount.totalCount;
    },
    selectCoupons(): void {
      this.$emit('changeDownloadCouponList', this.checkedCouponList);
      this.closeModal();
    },
    addList(
      event: any,
      coupon: CouponAPIResponse & { checked: boolean }
    ): void {
      console.log(`event : ${event}`);

      if (coupon.issueType !== CouponIssueType.DOWNLOAD) {
        this.showErrorAlert({ message: '다운로드 쿠폰이 아닙니다.' }, '');
        return;
      }

      const downloadableCouponInfo = {
        coupon: coupon,
        id: null,
        status: null,
      };

      event
        ? this.checkedCouponList.push(downloadableCouponInfo)
        : this.removeFromCheckedList(coupon);
    },
    removeFromCheckedList(
      checkedCoupon: CouponAPIResponse & { checked: boolean }
    ): void {
      console.log('remove function');
      console.log(this.checkedCouponList);
      const index = this.checkedCouponList.findIndex(coupon => {
        console.log(coupon);
        return coupon.coupon.id === checkedCoupon.id;
      });

      this.checkedCouponList.splice(index, 1);
    },
    checkedAllCoupons(event: boolean): void {
      this.couponListCheck(event);
      if (event) {
        this.coupons.map(coupon => {
          if (coupon.issueType === CouponIssueType.DOWNLOAD) {
            this.checkedCouponList.push({
              coupon,
              id: null,
              status: null,
            });
          }
        });
      } else {
        this.checkedCouponList = [];
      }
    },
    couponListCheck(value: boolean): void {
      this.coupons.map((coupon: any) => {
        if (coupon.issueType === CouponIssueType.DOWNLOAD) {
          coupon.checked = value;
        }
      });
    },
  },
});
