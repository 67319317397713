import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import {
  BulkIssuance,
  CouponBulkIssuanceParamInput,
} from '@/domain/coupon/model/bulkIssuance';
import { ISSUE_COUPON_BY_ID_WITH_USER_IDS } from '@/domain/coupon/queries/mutation';
import {
  BulkIssuanceItem,
  BulkIssuanceItemFilterInput,
} from '@/domain/coupon/model/bulkIssuanceItem';
import { BULK_ISSUANCE_ITEM_LIST } from '@/domain/coupon/queries/query';
import { Connection } from '@frientrip/domain';

export class IssuanceCouponService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  async issueCouponByIdWithUserIds(
    param: CouponBulkIssuanceParamInput
  ): Promise<BulkIssuance> {
    const response = await this.apollo.mutate({
      mutation: ISSUE_COUPON_BY_ID_WITH_USER_IDS,
      variables: {
        param,
      },
    });

    return response.data.issueCouponByIdWithUserIds;
  }

  async getBulkIssuanceItemList(
    filter: BulkIssuanceItemFilterInput
  ): Promise<Connection<BulkIssuanceItem>> {
    const response = await this.apollo.query({
      query: BULK_ISSUANCE_ITEM_LIST,
      variables: {
        filter,
      },
    });

    return response.data.coupon.bulkIssuanceItemList;
  }
}
