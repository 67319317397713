





































































import Vue from 'vue';
import { Option, Select } from 'element-ui';
import { COUPON_LIST_QUERY } from '@/domain/coupon/queries/query';
import { GET_USER_LIST_BY_FILTER_QUERY } from '@/domain/user/graphqls/user';
import {
  IssuedCouponFilterType,
  WithDrawAllIssuedCouponRequestInput,
} from '@/domain/coupon/model/issuedCoupon';
import { IssuedCouponStatus } from '@/domain/coupon/model/IssuedCouponStatus';
import { IssuedCouponService } from '@/domain/coupon/service/IssuedCouponService';
import { apolloClient } from '@/apolloClient';
import router from '@/router';

const issuedCouponService = new IssuedCouponService(apolloClient);

export default Vue.extend({
  name: 'IssuedCouponFilter',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      usableTerm: {
        startedAt: 0,
        endedAt: 0,
      },
      used: null,
      usedOption: [
        {
          text: '사용 가능',
          value: 'usable',
        },
        {
          text: '사용됨',
          value: 'used',
        },
        {
          text: '회수됨',
          value: 'withdraw',
        },
      ],
      couponInfoOptions: [
        {
          text: '쿠폰 ID',
          value: 'couponId',
        },
        {
          text: '쿠폰명',
          value: 'couponTitle',
        },
      ],
      couponInfoOptionValue: 'couponId',
      couponInfoValue: '',
      userInfoOptions: [
        {
          text: '크루 연락처',
          value: 'userPhoneNumber',
        },
        {
          text: '크루명',
          value: 'userName',
        },
        {
          text: 'ID',
          value: 'userId',
        },
        {
          text: '이메일',
          value: 'userEmail',
        },
      ],
      userInfoOptionValue: 'userPhoneNumber',
      userInfoValue: '',
    };
  },
  methods: {
    async addFilter(): Promise<void> {
      let usableTerm = null;
      if (this.usableTerm.startedAt > 0 && this.usableTerm.endedAt > 0) {
        usableTerm = {
          startedAt: this.usableTerm.startedAt,
          endedAt: this.usableTerm.endedAt,
        };
      }

      let filter: IssuedCouponFilterType = {
        usableTerm: usableTerm,
        used: this.used === 'used' ? true : false,
        status:
          this.used === 'withdraw'
            ? IssuedCouponStatus.WITHDRAW
            : IssuedCouponStatus.ACTIVE,
      };

      if (this.couponInfoValue !== '') {
        filter.couponId = await this.getCouponId();
      }

      console.log(this.userInfoValue);
      if (this.userInfoValue !== '') {
        filter.userId = await this.getUserId();
      }

      console.log('filter', filter);

      this.$emit('addFilter', filter);
    },
    async getCouponId(): Promise<number> {
      if (this.couponInfoOptionValue === 'couponId') {
        return Number(this.couponInfoValue);
      }

      return await this.findCouponByTitle();
    },
    async findCouponByTitle(): Promise<number> {
      const result = await this.$apollo.query({
        query: COUPON_LIST_QUERY,
        variables: {
          filter: {
            title: this.couponInfoValue,
          },
          size: 1,
          page: 1,
        },
      });

      console.log(result.data.coupon.coupons.edges[0]);

      if (result.data.coupon.coupons.edges.length === 0) {
        return 0;
      }

      return Number(result.data.coupon.coupons.edges[0].node.id);
    },
    async getUserId(): Promise<number> {
      if (this.userInfoOptionValue === 'userId') {
        return Number(this.userInfoOptionValue);
      }

      return await this.findUserByPhoneNumberOrNameOrEmail();
    },
    async findUserByPhoneNumberOrNameOrEmail(): Promise<number> {
      const value = this.userInfoValue;
      const filterUseCase: any = {
        userPhoneNumber: (function () {
          return {
            mobileNumberLike: value,
          };
        })(),
        userName: (function () {
          return {
            nicknameLike: value,
          };
        })(),
        userEmail: (function () {
          return {
            emailLike: value,
          };
        })(),
      };

      const filter = filterUseCase[this.userInfoOptionValue];
      console.log(filter);
      const result = await this.$apollo.query({
        query: GET_USER_LIST_BY_FILTER_QUERY,
        variables: {
          filter: filter,
          page: 1,
          size: 1,
        },
      });
      console.log(result);
      if (result.data.users.totalCount === 0) {
        return 0;
      }

      return Number(result.data.users.edges[0].node.id);
    },
    async withdrawAllCoupon(): Promise<void> {
      this.$modal.show(
        {
          title: '쿠폰 전체 회수!',
          message: '쿠폰을 전체 회수 하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            const param: WithDrawAllIssuedCouponRequestInput = {
              couponId: Number(this.$route.query.couponId),
            };
            let response = await issuedCouponService.withdrawAllIssuedCoupon(
              param
            );
            this.$modal.show(
              {
                title: '쿠폰 회수 완료!',
                message:
                  '총 ' +
                  response.withdrawAllIssuedCoupon.totalCount +
                  '개 중 ' +
                  response.withdrawAllIssuedCoupon.numberOfSuccess +
                  '개의 쿠폰 회수를 완료했습니다!',
                type: 'success',
              },
              async () => {
                router.go(0);
              }
            );
          } catch (err) {
            this.$modal.show({
              title: '쿠폰 회수 실패!',
              message: err.message.replace('GraphQL error:', ''),
              type: 'info',
            });
          }
        },
        () => {
          return;
        }
      );
    },
  },
});
