





























































































































































import { ContainerMixin } from '@/common/mixin/containerMixin';
import CollectionAndMagazineSearchInput from '@/domain/coupon/components/input/CollectionAndMagazineSearchInput.vue';
import { Table, TableColumn } from 'element-ui';
import {
  DownloadCouponButtonDisplayPositionType,
  DownloadCouponButtonDisplayPositionTypeLabel,
} from '@/domain/coupon/model/DownloadCouponButtonDisplayPositionType';
import { DownloadCouponButtonInfoParam } from '@/domain/coupon/model/DownloadCouponButtonInfoParam';
import { DownloadCouponTargetInfoType } from '@/domain/coupon/model/downloadCouponTargetInfoType';
import { downloadCouponTargetInfoOptions } from '@/domain/coupon/constants/downloadCouponTargetInfoOptions';
import CouponSearch from '@/domain/coupon/components/modal/CouponSearch.vue';
import { DOWNLOAD_COUPON_TARGET_INFO } from '@/domain/coupon/queries/query';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import {
  CouponAPIResponse,
  DownloadableCouponInfo,
  DownloadCouponButtonInfo,
  DownloadCouponTargetInfo,
  DownloadCouponTargetInfosInput,
} from '@/domain/coupon/model/coupon';
import DownloadCouponButtonImageUpload from '@/domain/coupon/components/modal/DownloadCouponButtonImageUpload.vue';
import { CommonState } from '@frientrip/domain';
import MagazineSearchInput from '@/domain/coupon/components/input/MagazineSearchInput.vue';
import FripMagazineSearchInput from '@/domain/coupon/components/input/FripMagazineSearchInput.vue';
import { DownloadCouponService } from '@/domain/coupon/service/DownloadCouponService';
import { apolloClient } from '@/apolloClient';

const downloadCouponService = new DownloadCouponService(apolloClient);

function getDownloadCouponTargetInfoOptionValue(
  type: DownloadCouponTargetInfoType
): string {
  return (
    downloadCouponTargetInfoOptions.find(i => i.value.toString() == type)
      ?.text || ''
  );
}

function getDownloadCouponButtonDisplayPositionTypeValue(
  label: string
): DownloadCouponButtonDisplayPositionType | null {
  return (
    DownloadCouponButtonDisplayPositionTypeLabel.find(i => i.label == label)
      ?.value || null
  );
}

export default ContainerMixin.extend({
  name: 'DownloadCouponDetail',
  components: {
    MagazineSearchInput,
    FripMagazineSearchInput,
    DownloadCouponButtonImageUpload,
    CollectionAndMagazineSearchInput,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    CouponSearch,
  },
  computed: {
    getTypeLabel(): string {
      switch (this.downloadCouponTargetInfo.targetType) {
        case DownloadCouponTargetInfoType.FripMagazine:
          return '매거진 ID';
        case DownloadCouponTargetInfoType.Magazine:
          return '구 매거진 ID';
        case DownloadCouponTargetInfoType.Collection:
          return '테마기획전 ID';

        default:
          return '매거진 ID';
      }
    },
    buttonName(): string {
      return this.downloadCouponTargetInfo.id === 0 ? '등록하기' : '수정하기';
    },
  },
  data(): {
    getDownloadCouponButtonDisplayPositionTypeLabel: (
      label: DownloadCouponButtonDisplayPositionType
    ) => string | null;
    buttonImageExposure: { text: string; value: CommonState }[];
    downloadCouponButtonInfoParam: DownloadCouponButtonInfoParam[];
    downloadCouponTypes: {
      text: string;
      value: DownloadCouponTargetInfoType;
    }[];
    downloadCouponType: DownloadCouponTargetInfoType;
    isOpenedCouponSearchModal: boolean;
    id: string;
    downloadCouponTargetInfo: DownloadCouponTargetInfo;
    isOpenedDownloadButtonImageUploadModal: boolean;
    downloadCouponButton: DownloadCouponButtonInfo;
    downloadCouponButtonDisplayPositionType: DownloadCouponButtonDisplayPositionType;
    downloadCouponButtonContent: { id: string; url: string };
    typeLabel: string;
    deleteDownloadableCouponInfoList: DownloadableCouponInfo[];
    buttonImageNames: {
      position: DownloadCouponButtonDisplayPositionType;
      imageName: string;
    }[];
  } {
    return {
      getDownloadCouponButtonDisplayPositionTypeLabel: (
        type: DownloadCouponButtonDisplayPositionType
      ) => {
        return (
          DownloadCouponButtonDisplayPositionTypeLabel.find(
            i => i.value.toString() == type
          )?.label || ''
        );
      },
      buttonImageExposure: [
        { text: '노출', value: CommonState.ACTIVE },
        { text: '비노출', value: CommonState.INACTIVE },
      ],
      downloadCouponButtonInfoParam: [],
      downloadCouponTypes: [
        {
          text: getDownloadCouponTargetInfoOptionValue(
            DownloadCouponTargetInfoType.Collection
          ),
          value: DownloadCouponTargetInfoType.Collection,
        },
        {
          text: getDownloadCouponTargetInfoOptionValue(
            DownloadCouponTargetInfoType.Magazine
          ),
          value: DownloadCouponTargetInfoType.Magazine,
        },
        {
          text: getDownloadCouponTargetInfoOptionValue(
            DownloadCouponTargetInfoType.FripMagazine
          ),
          value: DownloadCouponTargetInfoType.FripMagazine,
        },
      ],
      downloadCouponType: DownloadCouponTargetInfoType.Collection,
      isOpenedCouponSearchModal: false,
      id: this.$route.params.id || '',
      downloadCouponTargetInfo: {
        id: 0,
        description: '',
        downloadCouponButtonInfos: [
          {
            id: null,
            displayPosition: DownloadCouponButtonDisplayPositionType.UP,
            buttonImage: {
              id: '',
              uri: '',
            },
            status: CommonState.ACTIVE,
          },
          {
            id: null,
            displayPosition: DownloadCouponButtonDisplayPositionType.DOWN,
            buttonImage: {
              id: '',
              uri: '',
            },
            status: CommonState.ACTIVE,
          },
        ],
        downloadableCoupons: [],
        downloadableCouponInfos: [],
        status: CommonState.ACTIVE,
        targetId: '',
        targetType: DownloadCouponTargetInfoType.Collection,
        title: '',
      },
      isOpenedDownloadButtonImageUploadModal: false,
      downloadCouponButton: {} as DownloadCouponButtonInfo,
      downloadCouponButtonDisplayPositionType:
        DownloadCouponButtonDisplayPositionType.UP,
      downloadCouponButtonContent: { id: '', url: '' },
      typeLabel: '테마기획전 ID',
      deleteDownloadableCouponInfoList: [],
      buttonImageNames: [
        { position: DownloadCouponButtonDisplayPositionType.UP, imageName: '' },
        {
          position: DownloadCouponButtonDisplayPositionType.DOWN,
          imageName: '',
        },
      ],
    };
  },
  methods: {
    showCouponSearchModal(): void {
      this.isOpenedCouponSearchModal = true;
    },
    closeCouponSearchModal(): void {
      this.isOpenedCouponSearchModal = false;
    },
    //업로드 모달 수정 필요............
    showDownloadCouponButtonImageUploadModal(
      downloadButtonInfo: DownloadCouponButtonInfo
    ): void {
      this.isOpenedDownloadButtonImageUploadModal = true;
      this.downloadCouponButton = downloadButtonInfo;
      this.downloadCouponButtonContent = {
        id: downloadButtonInfo ? downloadButtonInfo.buttonImage.id : '',
        url: downloadButtonInfo ? downloadButtonInfo.buttonImage.uri : '',
      };
      this.downloadCouponButtonDisplayPositionType =
        downloadButtonInfo.displayPosition;
    },
    closeDownloadCouponButtonImageUploadModal(args?: {
      buttonImage: { id: string; url: string };
      displayPosition: DownloadCouponButtonDisplayPositionType;
    }): void {
      this.isOpenedDownloadButtonImageUploadModal = false;

      if (args) {
        const displayPosition = getDownloadCouponButtonDisplayPositionTypeValue(
          args.displayPosition
        )!;

        this.downloadCouponTargetInfo.downloadCouponButtonInfos.map(
          downloadCouponButtonInfo => {
            if (
              downloadCouponButtonInfo.displayPosition === args.displayPosition
            ) {
              downloadCouponButtonInfo.buttonImage.id = args.buttonImage.id;
              downloadCouponButtonInfo.buttonImage.uri = args.buttonImage.url;
            }
          }
        );

        this.$forceUpdate();
      }
    },
    changeDownloadCouponList(checkedCoupons: DownloadableCouponInfo[]): void {
      // 리스트에 값을 더해야될듯.. 있으면
      checkedCoupons.map(checkedCoupon => {
        const deleteCouponIndex =
          this.deleteDownloadableCouponInfoList.findIndex(
            deleteCoupon => deleteCoupon.coupon.id === checkedCoupon.coupon.id
          );

        if (
          deleteCouponIndex !== -1 &&
          this.deleteDownloadableCouponInfoList.length > 0
        ) {
          const coupon =
            this.deleteDownloadableCouponInfoList[deleteCouponIndex];
          coupon.status = CommonState.ACTIVE;
          this.downloadCouponTargetInfo.downloadableCouponInfos.push(coupon);
          this.deleteDownloadableCouponInfoList.splice(deleteCouponIndex, 1);
        } else {
          this.downloadCouponTargetInfo.downloadableCouponInfos.push(
            checkedCoupon
          );
        }
      });
    },
    removeDownloadableCouponList(coupon: CouponAPIResponse): void {
      const index =
        this.downloadCouponTargetInfo.downloadableCouponInfos.findIndex(
          (downloadableCoupon: DownloadableCouponInfo) => {
            return downloadableCoupon.coupon.id === coupon.id;
          }
        );

      this.deleteDownloadableCouponInfoList.push(
        this.downloadCouponTargetInfo.downloadableCouponInfos[index]
      );

      this.downloadCouponTargetInfo.downloadableCouponInfos.splice(index, 1);
    },
    async register(): Promise<void> {
      console.log(`router params ${this.$route.params.id}`);
      try {
        const param = this.buildDownloadCouponTargetInfoParam(
          this.downloadCouponTargetInfo
        );

        const isValid = this.validDownloadCouponTargetInfoParam(param);

        const uploadedNoImage =
          this.downloadCouponTargetInfo.downloadCouponButtonInfos.find(
            buttonInfo => {
              const isFindUploadImage =
                buttonInfo.status === CommonState.ACTIVE &&
                (buttonInfo.buttonImage.id === '' ||
                  buttonInfo.buttonImage.id.toString() === '0');
              return isFindUploadImage;
            }
          );

        if (
          !isValid &&
          (uploadedNoImage?.buttonImage.id === '' ||
            uploadedNoImage?.buttonImage.id === '0')
        ) {
          return this.showErrorMessage('등록 실패', {
            message: '이미지를 업로드 해주세요.',
          });
        }

        if (!isValid) {
          return this.showErrorMessage('등록 실패', {
            message: '필수 값을 등록해 주세요.',
          });
        }

        if (!this.$route.params.id) {
          this.$modal.show(
            {
              title: '등록!',
              message: '등록 후에는 기획전/매거진 ID 수정이 불가합니다.',
              type: 'info',
              showCancelButton: true,
              cancelText: '취소',
            },
            async () => {
              try {
                const saveDownloadCouponTargetInfo =
                  await downloadCouponService.createDownloadCouponTargetInfo(
                    param
                  );

                if (saveDownloadCouponTargetInfo.id) {
                  this.$modal.show(
                    {
                      title: '다운로드 쿠폰 등록 성공!',
                      message: '등록이 완료 되었습니다.',
                      type: 'success',
                    },
                    async () => {
                      await this.$router.push('/coupon/download-coupon/list');
                    }
                  );
                }
              } catch (e: any) {
                console.log(`register catch`);
                const errorMessage = this.parseErrorMessage(e.message);
                this.showErrorMessage(errorMessage, e);
              }
            },
            () => {
              return;
            }
          );
        } else {
          const saveDownloadCouponTargetInfo =
            await downloadCouponService.createDownloadCouponTargetInfo(param);

          this.$modal.show(
            {
              title: '다운로드 쿠폰 수정 성공!',
              message: '수정이 완료되었습니다.',
              type: 'success',
            },
            async () => {
              await this.$router.push('/coupon/download-coupon/list');
            }
          );
        }
      } catch (e: any) {
        console.log(`register catch`);
        const errorMessage = this.parseErrorMessage(e.message);
        this.showErrorMessage(errorMessage, e);
      }
    },
    buildDownloadCouponTargetInfoParam(
      targetInfo: DownloadCouponTargetInfo
    ): DownloadCouponTargetInfosInput {
      const param: DownloadCouponTargetInfosInput = {
        id: null,
        description: '',
        downloadableCouponInfos: [],
        downloadCouponButtonInfos: [],
        status: CommonState.ACTIVE,
        targetId: '',
        targetType: DownloadCouponTargetInfoType.Collection,
        title: '',
      };

      param.targetId = targetInfo.targetId;
      param.targetType = targetInfo.targetType;
      param.description = targetInfo.description;
      param.title = targetInfo.title;

      /**
       * buttonInfo param build 등록시에는 'ACTIVE' 상태값만 등록하면 됨...
       * TODO: 좀더 명확한 등록 방법이 필요 할듯....
       */
      targetInfo.downloadCouponButtonInfos.map(buttonInfo => {
        if (buttonInfo.status === CommonState.ACTIVE) {
          param.downloadCouponButtonInfos.push({
            buttonImageId: buttonInfo.buttonImage.id,
            id: buttonInfo.id ? buttonInfo.id.toString() : null,
            displayPosition: buttonInfo.displayPosition,
            status: buttonInfo.status,
          });
        }
      });

      targetInfo.downloadableCouponInfos.map(couponInfo => {
        param.downloadableCouponInfos.push({
          couponId: couponInfo.coupon.id.toString(),
          id: couponInfo.id,
          status: couponInfo.status,
        });
      });

      if (targetInfo.id) {
        if (this.deleteDownloadableCouponInfoList.length > 0) {
          this.deleteDownloadableCouponInfoList.map(deleteCoupon => {
            param.downloadableCouponInfos.push({
              couponId: deleteCoupon.coupon.id.toString(),
              id: deleteCoupon.id,
              status: CommonState.DELETED,
            });
          });
        }

        param.id = targetInfo.id.toString();
      }

      return param;
    },
    moveListPage(): void {
      this.$router.push('/coupon/download-coupon/list');
    },
    setTargetInfo(targetInfo: { targetId: string; targetTitle: string }): void {
      this.downloadCouponTargetInfo.targetId = targetInfo.targetId;
      this.downloadCouponTargetInfo.title = targetInfo.targetTitle;
    },
    parseErrorMessage(errorMessage: string): string {
      if (
        errorMessage.includes('DuplicatedTargetInfoOfDownloadCouponException')
      ) {
        return '중복된 매거진 또는 기획전 id가 존재합니다.';
      }

      return '필수값을 등록하지 않았습니다';
    },
    validDownloadCouponTargetInfoParam(
      param: DownloadCouponTargetInfosInput
    ): boolean {
      let activeCountDownloadableCountInfo = 0;

      if (!param.targetId) {
        return false;
      }

      if (param.downloadableCouponInfos.length === 0) {
        return false;
      }
      console.log(
        `param downloadCouponInfos : ${JSON.stringify(
          param.downloadableCouponInfos
        )}`
      );
      param.downloadableCouponInfos.map(downloadCouponInfo => {
        if (
          downloadCouponInfo.status === CommonState.ACTIVE ||
          downloadCouponInfo.status === null
        ) {
          activeCountDownloadableCountInfo++;
        }
      });
      console.log(activeCountDownloadableCountInfo);
      if (!activeCountDownloadableCountInfo) {
        return false;
      }

      const validationButtonImage = param.downloadCouponButtonInfos.find(
        buttonInfo =>
          buttonInfo.buttonImageId === '' ||
          buttonInfo.buttonImageId.toString() === '0'
      );

      if (validationButtonImage) {
        return false;
      }

      return true;
    },
  },
  watch: {
    downloadCouponTargetInfo: {
      handler(val) {
        this.downloadCouponTargetInfo = val;
      },
      deep: true,
    },
    'downloadCouponTargetInfo.downloadCouponButtonInfos': {
      handler(val) {
        this.downloadCouponTargetInfo.downloadCouponButtonInfos = val;
      },
      deep: true,
    },
    'downloadCouponTargetInfo.downloadableCouponInfos': {
      handler(val) {
        this.downloadCouponTargetInfo.downloadableCouponInfos = val;
      },
      deep: true,
    },
  },

  apollo: {
    downloadCoupon: {
      query: DOWNLOAD_COUPON_TARGET_INFO,
      variables(): { id: string } {
        return {
          id: this.id,
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{
          coupon: { downloadCouponTargetInfo: DownloadCouponTargetInfo };
        }>
      ) {
        this.downloadCouponTargetInfo =
          result.data.coupon.downloadCouponTargetInfo;

        DownloadCouponButtonDisplayPositionTypeLabel.map(option => {
          const findDownloadCouponButton =
            this.downloadCouponTargetInfo.downloadCouponButtonInfos.find(
              downloadCouponButton => {
                return option.value === downloadCouponButton.displayPosition;
              }
            );

          if (!findDownloadCouponButton) {
            this.downloadCouponTargetInfo.downloadCouponButtonInfos.push({
              id: null,
              displayPosition: option.value,
              buttonImage: {
                id: '0',
                uri: '',
              },
              status: CommonState.INACTIVE,
            });
          }
        });

        //무조건 상단이 위로오게 정렬을 해야될듯...
        const upPositionIndex =
          this.downloadCouponTargetInfo.downloadCouponButtonInfos.findIndex(
            buttonInfo =>
              buttonInfo.displayPosition ===
              DownloadCouponButtonDisplayPositionType.UP
          );

        if (upPositionIndex === 1) {
          const upButtonInfo =
            this.downloadCouponTargetInfo.downloadCouponButtonInfos.splice(
              upPositionIndex,
              1
            );

          this.downloadCouponTargetInfo.downloadCouponButtonInfos.splice(
            0,
            0,
            upButtonInfo[0]
          );
        }
      },
      fetchPolicy: 'no-cache',
      update: data => data.coupon.downloadCouponTargetInfo,
      skip(): boolean {
        return this.$route.params.id === '' || !this.$route.params.id;
      },
    },
  },
});
