
























































































































import InfoLabel from '@/components/Labels/InfoLabel.vue';
import Vue from 'vue';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { Table, TableColumn } from 'element-ui';

interface PageInfo {
  hasNextPage: boolean;
  endCursor: string;
}

interface ProductEdge {
  node: Product;
  cursor: string;
}

interface ProductConnection {
  edges: ProductEdge[];
  totalCount: number;
  pageInfo: PageInfo;
}

interface Product {
  id: number;
  title: string;
  catchphrase?: string;
  type?: string;
}

interface ProductSearchParam {
  page?: number;
  size?: number;
  filter: ProductFilterInput;
}

interface ProductFilterInput {
  titleLike?: string;
  ids?: string[];
}

const PRODUCTS_QUERY = gql`
  query getProductsBytitleLike(
    $page: Int
    $size: Int
    $filter: ProductFilterInput
  ) {
    product {
      products(page: $page, size: $size, filter: $filter) {
        totalCount
        edges {
          node {
            id
            title
            catchphrase
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export default Vue.extend({
  name: 'SearchProductsContainer',
  components: {
    InfoLabel,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    checkedList: {
      type: Array,
    },
  },
  data() {
    return {
      page: 1,
      size: 10,
      totalRows: 0,
      title: '',
      searchTitle: '',
      products: { edges: [] },
      selectedProducts: [] as Product[],
      allChecked: false,
      checkedProductList: [],
      searchKeyword: '',
      searchIds: [] as string[],
      filter: {
        titleLike: '',
        ids: [],
      } as ProductFilterInput,
    };
  },
  computed: {},
  apollo: {
    products: {
      query: PRODUCTS_QUERY,
      variables(): ProductSearchParam {
        return {
          page: this.page,
          size: this.size,
          filter: this.filter,
        };
      },
      result(
        result: ApolloQueryResult<{ product: { products: ProductConnection } }>
      ): void {
        console.log(result);
        let checkedCount = 0;
        this.totalRows = result.data.product.products.totalCount;

        this.$data.products = result.data.product.products.edges.map(
          (product: ProductEdge) => {
            const node = product.node;
            const checkStatus =
              this.$data.checkedProductList.findIndex(
                (checkProduct: { id: number }) => checkProduct.id === node.id
              ) !== -1;

            if (checkStatus) {
              checkedCount++;
            }

            return {
              id: node.id,
              title: node.title,
              catchphrase: node.catchphrase,
              checked: checkStatus,
            };
          }
        );

        this.$data.allChecked = checkedCount === this.$data.products.length;
        console.log('??');
        console.log(this.$data.products);
      },
      skip(): boolean {
        return this.searchTitle === '' && this.searchIds.length === 0;
      },
      update: data => data.product.products,
    },
  },
  methods: {
    searchProductsByTitle(): void {
      this.products = { edges: [] };
      this.searchIds = [];
      this.searchTitle = '';

      const keywordArray = this.searchKeyword.replace(' ', '').split(',');
      console.log(keywordArray);
      const numberCheckArray: string[] = keywordArray.filter((keyword: any) => {
        // console.log(isNumeric);
        keyword = keyword.trim();
        return !isNaN(keyword);
      });

      if (numberCheckArray.length > 0) {
        this.searchIds = numberCheckArray;
        this.filter.ids = numberCheckArray;
        delete this.filter.titleLike;
      } else {
        this.searchTitle = this.searchKeyword;
        this.filter.titleLike = this.searchKeyword;
        delete this.filter.ids;
      }

      this.$apollo.queries.products.refetch();
    },
    summaryDescription(catchphrase: string): string {
      return catchphrase.slice(0, 50).concat('...');
    },
    addProduct(product: Product): void {
      this.selectedProducts.push(product);
      this.$emit('input', this.selectedProducts);
    },
    deleteProductById(productId: number): void {
      const index = this.selectedProducts.findIndex(
        (product: Product) => product.id === productId
      );
      this.selectedProducts.splice(index, 1);
      this.$emit('input', this.selectedProducts);
    },
    pageClick(bvEvent: any, page: number): void {
      console.log('page clicked!', page);
    },
    checkedAllProduct(event: boolean): void {
      this.productListCheck(event);
      this.$emit('changeSelectList', this.$data.checkedProductList);
    },
    productListCheck(value: boolean): void {
      this.$data.products.map((product: Product & { checked: boolean }) => {
        if (value) {
          if (!this.existDuplicateRow(product)) {
            this.$data.checkedProductList.push(product);
          }
        } else {
          this.removeFromCheckedList(product);
        }

        product.checked = value;
      });
    },
    addList(event: any, product: Product & { checked?: boolean }): void {
      console.log(event);
      product.checked = event;
      if (event) {
        console.log(product);
        this.$data.checkedProductList.push(product);
      } else {
        this.removeFromCheckedList(product);
      }

      this.$emit('changeSelectList', this.$data.checkedProductList);
    },
    removeFromCheckedList(checkedProduct: Product): void {
      const index = this.$data.checkedProductList.findIndex(
        (product: Product) => {
          return product.id === checkedProduct.id;
        }
      );
      this.$data.checkedProductList.splice(index, 1);
    },
    existDuplicateRow(product: Product & { checked?: boolean }): boolean {
      return this.$data.checkedProductList.find(
        (row: Product & { checked?: boolean }) => {
          return row.id === product.id;
        }
      );
    },
  },
});
