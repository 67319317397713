

































import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import Vue, { VueConstructor } from 'vue';
import axios from 'axios';
import { getLegacyApiUrl } from '@/env';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { Collection } from '@/domain/collection/model/collection';

import gql from 'graphql-tag';

interface Magazine {
  id: string;
  title: string;
}

export const GET_MAGAZINE = gql`
  query MagazineQuery($id: ID!) {
    fripMagazine {
      magazine(id: $id) {
        id
        title
      }
    }
  }
`;

export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLFormElement } }>
).extend({
  name: 'FripMagazineSearchInput',
  components: {
    VueBootstrapTypeahead,
  },
  model: {
    event: 'select',
  },
  props: {
    value: {
      type: String,
    },
    id: {
      type: Number,
    },
  },
  data(): {
    searchMagazineId: string;
    magazineId: string;
    magazines: Magazine[];
    title: string;
  } {
    return {
      searchMagazineId: this.value !== '' ? this.value : '',
      magazineId: '',
      magazines: [],
      title: '',
    };
  },
  watch: {
    value(newValue: string) {
      if (newValue === '') {
        this.$refs.magazineSearchInput.inputValue = newValue;
      }
      this.searchMagazineId = newValue;
    },
  },
  beforeMount() {
    if (this.id) {
      this.selectMagazine();
    }
  },
  methods: {
    selectMagazine(): void {
      this.$emit('select ', {
        targetId: this.magazineId,
        targetTitle: this.title,
      });
    },
  },
  apollo: {
    magazine: {
      query: GET_MAGAZINE,
      variables(): { id: number } {
        return {
          id: Number(this.searchMagazineId),
        };
      },
      result(
        result: ApolloQueryResult<{ fripMagazine: { magazine: Magazine } }>
      ): void {
        if (result.data?.fripMagazine?.magazine) {
          this.magazineId = result.data.fripMagazine.magazine.id;
          this.title = result.data.fripMagazine.magazine.title;
          this.magazines[0] = result.data.fripMagazine.magazine;
        } else {
          this.magazineId = '';
          this.title = '';
          this.magazines = [];
        }
        this.$emit('select', {
          targetId: this.magazineId,
          targetTitle: this.title,
        });
      },
      error(error: ApolloError) {
        console.log(error);
      },
      skip(): boolean {
        return this.searchMagazineId == '';
      },
    },
  },
});
