import {
  CouponPolicy,
  CreateCouponPolicyRequestParam,
} from '../model/CouponPolicy';
import { couponPolicyUseCaseService } from '@/domain/coupon/service/CouponPolicyUseCaseService';

export const convertCouponPolicyToRequestParam = (
  policy: CouponPolicy
): CreateCouponPolicyRequestParam => {
  return {
    reference: {
      id: policy.reference.id.toString(),
      type: policy.reference.type,
    },
    allowed: policy.allowed,
  };
};

export const convertPolicies = async (
  couponPolicies: CouponPolicy[]
): Promise<CouponPolicy[]> => {
  couponPolicies = await couponPolicyUseCaseService.attachReferenceDisplayName(
    couponPolicies
  );

  return couponPolicies;
};
