var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{staticClass:"table table-responsive table-flush table-striped mt-3",attrs:{"header-row-class-name":"thead-light","empty-text":"쿠폰 발급 내역이 존재하지 않습니다.","data":_vm.bulkIssuanceList}},[_c('el-table-column',{attrs:{"label":"번호"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"발급 요청 시간"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$moment(row.requestedAt).format('YYYY-MM-DD HH:mm'))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"발급 유저"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.issuedCount)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"발급 상태"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('bulk-issuance-status-label',{attrs:{"status":row.status}})]}}])})],1),_c('div',[_c('b-pagination',{staticClass:"mt-3",attrs:{"total-rows":_vm.totalCount,"per-page":_vm.size,"align":"center","first-number":"","last-number":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }