import { CouponForm as CouponFormType } from '../model/coupon';
import { CouponKeyword, CouponKeywordState } from '../model/CouponKeyword';
import { CouponPolicy, CouponPolicyState } from '../model/CouponPolicy';
import { CouponKeywordService } from './CouponKeywordService';
import { CouponPolicyService } from './CouponPolicyService';
import { CouponService } from './CouponService';
import {
  convertFormToCreateRequestParam,
  convertFormToUpdateRequestParam,
} from '@/domain/coupon/util/couponConverter';

export class CouponUseCaseService {
  private readonly couponService;
  private readonly couponKeywordService;
  private readonly couponPolicyService;

  constructor(
    couponService: CouponService,
    couponKeywordService: CouponKeywordService,
    couponPolicyService: CouponPolicyService
  ) {
    this.couponService = couponService;
    this.couponKeywordService = couponKeywordService;
    this.couponPolicyService = couponPolicyService;
  }

  async createCouponWithKeywordsAndPolicies(
    coupon: CouponFormType,
    keywords: CouponKeyword[],
    policies: CouponPolicy[]
  ): Promise<number> {
    const result = await this.couponService.createCoupon(
      convertFormToCreateRequestParam(
        coupon,
        keywords.filter(i => i.status != CouponKeywordState.DELETED),
        policies.filter(i => i.status != CouponPolicyState.DELETED)
      )
    );

    return result.id;
  }

  async updateCouponWithKeywordsAndPolicies(
    coupon: CouponFormType,
    keywords: CouponKeyword[],
    policies: CouponPolicy[]
  ): Promise<void> {
    await this.couponService.updateCoupon(
      convertFormToUpdateRequestParam(
        coupon,
        keywords.filter(i => i.status != CouponKeywordState.DELETED),
        policies.filter(i => i.status != CouponPolicyState.DELETED)
      )
    );
  }
}
