

























































































































import InfoLabel from '@/components/Labels/InfoLabel.vue';
import Vue from 'vue';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { Table, TableColumn } from 'element-ui';

interface PageInfo {
  hasNextPage: boolean;
  endCursor: string;
}

interface HostEdge {
  node: Host;
  cursor: string;
}

interface HostConnection {
  edges: HostEdge[];
  totalCount: number;
  pageInfo: PageInfo;
}

interface Host {
  id: number;
  name: string;
  decription?: string;
  type?: string;
}

interface HostSearchParam {
  page?: number;
  size?: number;
  filter: HostFilter;
}

interface HostFilter {
  nameLike?: string;
  emailLike?: string;
  ids?: string[];
}

const HOSTS_QUERY = gql`
  query hosts($page: Int, $size: Int, $filter: HostFilter) {
    hosts(page: $page, size: $size, filter: $filter) {
      edges {
        node {
          id
          name
          description
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export default Vue.extend({
  name: 'SearchHostsContainer',
  components: {
    InfoLabel,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      totalRows: 0,
      name: '',
      searchName: '',
      hosts: { edges: [] },
      selectedHosts: [] as Host[],
      checkedHostList: [],
      allChecked: false,
      searchIds: [] as string[],
      searchKeyword: '',
      filter: {
        nameLike: '',
        ids: [],
      } as HostFilter,
    };
  },
  apollo: {
    hosts: {
      query: HOSTS_QUERY,
      variables(): HostSearchParam {
        return {
          page: this.page,
          size: this.perPage,
          filter: this.filter,
        };
      },
      result(result: ApolloQueryResult<{ hosts: HostConnection }>): void {
        let checkedCount = 0;
        this.totalRows = result.data.hosts.totalCount;

        this.$data.hosts = result.data.hosts.edges.map((edge: HostEdge) => {
          const node = edge.node;
          const checkStatus =
            this.$data.checkedHostList.findIndex(
              (checkHost: { id: number }) => checkHost.id === node.id
            ) !== -1;

          if (checkStatus) {
            checkedCount++;
          }

          return {
            id: node.id,
            title: node.name,
            checked: checkStatus,
          };
        });

        this.$data.allChecked = checkedCount === this.$data.hosts.length;
        console.log(this.$data.hosts);
      },
      skip(): boolean {
        return this.searchName === '' && this.searchIds.length === 0;
      },
    },
  },
  methods: {
    searchHostsByName(): void {
      this.hosts = { edges: [] };
      this.searchIds = [];
      this.searchName = '';

      const keywordArray = this.searchKeyword.replace(' ', '').split(',');

      const numberCheckArray: string[] = keywordArray.filter(
        (keyword: string) => {
          keyword = keyword.trim();
          return !isNaN(Number(keyword));
        }
      );

      if (numberCheckArray.length > 0) {
        this.searchIds = numberCheckArray;
        this.filter.ids = numberCheckArray;
        delete this.filter.nameLike;
      } else {
        this.searchName = this.searchKeyword;
        this.filter.nameLike = this.searchKeyword;
        delete this.filter.ids;
      }

      this.$apollo.queries.hosts.refetch();
    },
    summaryDescription(description: string): string {
      return description.slice(0, 50).concat('...');
    },
    addHost(host: Host): void {
      this.selectedHosts.push(host);
      this.$emit('input', this.selectedHosts);
    },
    deleteHostById(hostId: number): void {
      const index = this.selectedHosts.findIndex(
        (host: Host) => host.id === hostId
      );
      this.selectedHosts.splice(index, 1);
      this.$emit('input', this.selectedHosts);
    },
    pageClick(bvEvent: any, page: number): void {
      console.log('page clicked!', page);
    },
    checkedAllHost(event: boolean): void {
      this.hostListCheck(event);
      this.$emit('changeSelectList', this.$data.checkedHostList);
    },
    hostListCheck(value: boolean): void {
      this.$data.hosts.map((host: Host & { checked: boolean }) => {
        console.log(value);
        if (value) {
          if (!this.existDuplicateRow(host)) {
            console.log(host);
            this.$data.checkedHostList.push(host);
          }
        } else {
          this.removeFromCheckedList(host);
        }

        host.checked = value;
        console.log(host);
      });
    },
    addList(event: any, host: Host & { checked?: boolean }): void {
      console.log(event);
      host.checked = event;
      if (event) {
        console.log(host);
        this.$data.checkedHostList.push(host);
      } else {
        this.removeFromCheckedList(host);
      }

      this.$emit('changeSelectList', this.$data.checkedHostList);
    },
    removeFromCheckedList(checkedHost: Host): void {
      const index = this.$data.checkedHostList.findIndex((host: Host) => {
        return host.id === checkedHost.id;
      });
      this.$data.checkedHostList.splice(index, 1);
    },
    existDuplicateRow(host: Host & { checked?: boolean }): boolean {
      return this.$data.checkedHostList.find(
        (row: Host & { checked?: boolean }) => {
          return row.id === host.id;
        }
      );
    },
  },
});
