






























import { ContainerMixin } from '@/common/mixin/containerMixin';
import { DownloadCouponTargetInfoType } from '@/domain/coupon/model/downloadCouponTargetInfoType';
import { getDownloadCouponTargetInfoOptionTextByValue } from '@/domain/coupon/constants/downloadCouponTargetInfoOptions';
import { Option, Select } from 'element-ui';
import VueRouter from 'vue-router';
import { Dictionary } from 'vue-router/types/router';

interface downloadCouponFilter
  extends Dictionary<string | string[] | undefined | null> {
  targetType?: string;
  targetId?: string | null;
  couponId?: string;
  titleLike?: string | null;
  targetIdLike?: string | null;
  titleLikeOrTargetIdLike?: string | null;
}

export default ContainerMixin.extend({
  name: 'DownloadCouponFilter',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data(): {
    searchKeyword: string;
    downloadCouponFilterOptions: { text: string; value: string }[];
    downloadCouponFilterOptionTarget: string;
  } {
    return {
      searchKeyword: '',
      downloadCouponFilterOptions: [
        {
          text: '전체',
          value: 'all',
        },
        {
          text: '타겟 ID',
          value: 'targetIdLike',
        },
        {
          text: '타이틀',
          value: 'titleLike',
        },
      ],
      downloadCouponFilterOptionTarget: 'all',
    };
  },
  methods: {
    refresh(): void {
      const filter: downloadCouponFilter = {};

      if (this.downloadCouponFilterOptionTarget === 'targetIdLike') {
        filter.targetIdLike =
          this.searchKeyword !== '' ? this.searchKeyword : null;
      }

      if (this.downloadCouponFilterOptionTarget === 'titleLike') {
        filter.titleLike =
          this.searchKeyword !== '' ? this.searchKeyword : null;
      }

      if (this.downloadCouponFilterOptionTarget === 'all') {
        filter.titleLikeOrTargetIdLike =
          this.searchKeyword !== '' ? this.searchKeyword : null;
      }

      this.$router.push({ query: filter }).catch(err => {
        if (
          VueRouter.isNavigationFailure(
            err,
            VueRouter.NavigationFailureType.duplicated
          )
        ) {
          console.error(err.message);
        }
      });
      this.$emit('refresh', true);
    },
  },
});
