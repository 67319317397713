import { render, staticRenderFns } from "./IssuedCouponList.vue?vue&type=template&id=8b3a3c8e&scoped=true&"
import script from "./IssuedCouponList.vue?vue&type=script&lang=ts&"
export * from "./IssuedCouponList.vue?vue&type=script&lang=ts&"
import style0 from "./IssuedCouponList.vue?vue&type=style&index=0&id=8b3a3c8e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b3a3c8e",
  null
  
)

export default component.exports