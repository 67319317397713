




































































import { Table, TableColumn } from 'element-ui';
import BulkIssuanceStatusLabel from '@/domain/coupon/components/controls/BulkIssuanceStatusLabel.vue';
import {
  BULK_ISSUANCE_ITEM_LIST,
  BULK_ISSUANCE_LIST,
} from '@/domain/coupon/queries/query';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import {
  BulkIssuance,
  BulkIssuanceDetailState,
} from '@/domain/coupon/model/bulkIssuance';
import { CommonState, Connection, Edge } from '@frientrip/domain';
import {
  BulkIssuanceItem,
  BulkIssuanceItemFilterInput,
} from '@/domain/coupon/model/bulkIssuanceItem';
import { AppPushParam } from '@/common/model/AppPushParam';
import { SendAppPushService } from '@/common/service/SendAppPushService';
import { apolloClient } from '@/apolloClient';
import { ContainerMixin } from '@/common/mixin/containerMixin';
import { IssuanceCouponService } from '@/domain/coupon/service/IssuanceCouponService';

const COUPON_BULK_ISSUANCE = 'CouponBulkIssuance';
const SEND_COUPON_MESSAGE =
  '쿠폰이 발급되었습니다. 마이 > 쿠폰함에서 확인해주세요.';
const sendAppPushService = new SendAppPushService(apolloClient);
const issuanceCouponService = new IssuanceCouponService(apolloClient);

export default ContainerMixin.extend({
  name: 'IssuedCouponPushList',
  components: {
    BulkIssuanceStatusLabel,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    pageInfo: {
      type: Object,
    },
    coupon: {
      type: Object,
    },
  },
  data() {
    return {
      totalCount: 0,
      size: 10,
      page: 1,
      bulkIssuanceList: [],
    };
  },
  methods: {
    async sendPush(
      bulkIssuance: BulkIssuance & { loading: boolean }
    ): Promise<void> {
      bulkIssuance.loading = true;
      const id: string = bulkIssuance.id.toString();
      const bulkIssuanceItemListFilter: BulkIssuanceItemFilterInput = {
        couponBulkIssuanceId: Number(id),
      };

      try {
        const itemList = await issuanceCouponService.getBulkIssuanceItemList(
          bulkIssuanceItemListFilter
        );

        if (!itemList) {
          this.showErrorAlert(
            {
              message:
                '발송 대상이 존재 하지 않습니다. 관리자에게 문의해주세요.',
            },
            '발송 실패!'
          );
          return;
        }

        const customerIds: string[] = itemList.edges
          .filter((edge: Edge<BulkIssuanceItem>) => {
            return (
              edge.node.status === CommonState.ACTIVE &&
              edge.node.detailStatus === BulkIssuanceDetailState.NORMAL
            );
          })
          .map((edge: Edge<BulkIssuanceItem>) => {
            return edge.node.userId.toString();
          });

        if (customerIds.length > 1000) {
          this.showErrorAlert(
            { message: '1000명 이상의 대상은 푸시 발송을 보낼 수 없습니다.' },
            '발송 실패!'
          );

          return;
        }

        const pushParam: AppPushParam = {
          targetId: id,
          targetType: COUPON_BULK_ISSUANCE,
          title: `${this.coupon.title}`,
          body: SEND_COUPON_MESSAGE,
          deeplink: 'coupons',
        };

        const appPushResponse = await sendAppPushService.sendAppPush(
          pushParam,
          customerIds
        );

        if (!appPushResponse.success) {
          this.showErrorAlert(
            { message: appPushResponse.message },
            '발송 실패!'
          );

          return;
        }

        bulkIssuance.loading = false;
        await this.$apollo.queries.bulkIssuanceCouponList.refetch();
      } catch (err) {
        this.showErrorAlert(err, '푸시 발송 실패!');
      }
    },
  },

  apollo: {
    bulkIssuanceCouponList: {
      query: BULK_ISSUANCE_LIST,
      variables() {
        return {
          filter: {
            couponId: Number(this.coupon.id),
          },
          page: this.$data.page,
          size: this.$data.size,
        };
      },
      error(error: ApolloError): void {
        this.showErrorAlert(error.message);
      },
      result(
        result: ApolloQueryResult<{
          coupon: { bulkIssuanceList: Connection<BulkIssuance> };
        }>
      ): void {
        this.$data.bulkIssuanceList =
          result.data.coupon.bulkIssuanceList.edges.map(
            (edge: Edge<BulkIssuance>) => {
              return { ...edge.node, loading: false };
            }
          );

        this.$data.totalCount = result.data.coupon.bulkIssuanceList.totalCount;
      },
      skip(): boolean {
        return !this.$route.params.id || this.$route.params.id === '0';
      },
      update: data => data.bulkIssuanceCouponList,
    },
  },
});
