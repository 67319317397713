import {
  CouponPolicy,
  CouponPolicyReferenceType,
} from '@/domain/coupon/model/CouponPolicy';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-boost';
import { apolloClient } from '@/apolloClient';
import {
  GET_CATEGORIES_FOR_COUPON_POLICY_REF,
  GET_HOSTS_FOR_COUPON_POLICY_REF,
  GET_PRODUCTS_FOR_COUPON_POLICY_REF,
} from '@/domain/coupon/queries/useCaseQuery';
import { DisplayCategoryEdge } from '@/domain/category/display/model/DisplayCategoryGraphqltype';

interface HostNode {
  id: string;
  name: string;
}

interface ProductNode {
  id: string;
  title: string;
}

class CouponPolicyUseCaseService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  async attachReferenceDisplayName(
    policies: CouponPolicy[]
  ): Promise<CouponPolicy[]> {
    await Promise.all([
      this.attachCategoriesDisplayName(policies),
      this.attachHostsDisplayName(policies),
      this.attachProductsDisplayName(policies),
    ]);

    return policies;
  }

  async attachCategoriesDisplayName(policies: CouponPolicy[]): Promise<void> {
    const categoryRefPolicies = policies.filter(
      ref => ref.reference.type == CouponPolicyReferenceType.CATEGORY
    );

    if (categoryRefPolicies.length == 0) {
      return;
    }

    const categoryIds = this.getIdsFromPolicies(categoryRefPolicies);

    const response = await this.apollo.query({
      query: GET_CATEGORIES_FOR_COUPON_POLICY_REF,
      variables: {
        size: categoryIds.length,
        categoryIds,
      },
    });

    response.data?.category?.displays?.edges?.map((i: DisplayCategoryEdge) => {
      const policy = categoryRefPolicies.find(
        policy => policy.reference.id == i.node.id
      );

      if (policy) {
        policy.reference.displayName = i.node.label.name;
      }
    });
  }

  async attachHostsDisplayName(policies: CouponPolicy[]): Promise<void> {
    const hostRefPolicies = policies.filter(
      ref => ref.reference.type == CouponPolicyReferenceType.HOST
    );

    if (hostRefPolicies.length == 0) {
      return;
    }

    const hostIds = this.getIdsFromPolicies(hostRefPolicies);

    const response = await this.apollo.query({
      query: GET_HOSTS_FOR_COUPON_POLICY_REF,
      variables: {
        size: hostIds.length,
        hostIds,
      },
    });

    response.data?.hosts?.edges?.map((i: { node: HostNode }) => {
      const policy = hostRefPolicies.find(
        policy => policy.reference.id == i.node.id
      );

      if (policy) {
        policy.reference.displayName = i.node.name;
      }
    });
  }

  async attachProductsDisplayName(policies: CouponPolicy[]): Promise<void> {
    const productRefPolicies = policies.filter(
      ref => ref.reference.type == CouponPolicyReferenceType.PRODUCT
    );

    if (productRefPolicies.length == 0) {
      return;
    }

    const productIds = this.getIdsFromPolicies(productRefPolicies);

    const response = await this.apollo.query({
      query: GET_PRODUCTS_FOR_COUPON_POLICY_REF,
      variables: {
        size: productIds.length,
        productIds,
      },
    });

    response.data?.product?.products?.edges?.map((i: { node: ProductNode }) => {
      const policy = productRefPolicies.find(
        policy => policy.reference.id == i.node.id
      );

      if (policy) {
        policy.reference.displayName = i.node.title;
      }
    });
  }

  getIdsFromPolicies(policies: CouponPolicy[]): string[] {
    return policies.map(ref => ref.reference.id.toString());
  }
}

export const couponPolicyUseCaseService = new CouponPolicyUseCaseService(
  apolloClient
);
