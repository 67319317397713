

























import CouponPolicyList from './CouponPolicyList.vue';
import Vue from 'vue';
import { CouponPolicy } from '../model/CouponPolicy';
import { convertPolicies } from '../util/couponPolicyConverter';
import Spinner from '@/components/Spinner.vue';

export default Vue.extend({
  name: 'CouponPolicyRouter',
  components: {
    CouponPolicyList,
    Spinner,
  },
  props: {
    policies: {
      type: Array,
    },
  },
  data() {
    return {
      loading: false,
      couponPolicies: [] as CouponPolicy[],
      blackList: [] as CouponPolicy[],
      whiteList: [] as CouponPolicy[],
    };
  },
  async created() {
    await this.convertPolicies(this.policies as CouponPolicy[]);
  },
  methods: {
    async convertPolicies(policies: CouponPolicy[]) {
      this.loading = true;

      this.couponPolicies = await convertPolicies(policies);
      this.whiteList = this.couponPolicies.filter(i => i.allowed == true);
      this.blackList = this.couponPolicies.filter(i => i.allowed == false);
      this.loading = false;
    },
    async changePolicies(policies: CouponPolicy[], allowed: boolean) {
      const changedPolicies = allowed
        ? [...policies, ...this.blackList]
        : [...policies, ...this.whiteList];

      await this.convertPolicies(changedPolicies);
      this.$emit('changeCouponPolicies', changedPolicies);
    },
  },
});
