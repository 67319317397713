import { CouponAPIResponse } from '../model/coupon';
import {
  CouponPolicyRequestParam,
  CreateCouponPolicyRequestParam,
} from '../model/CouponPolicy';

import {
  CREATE_COUPON_POLICY_MUTATION,
  DELETE_COUPON_POLICY_MUTATION,
} from '../queries/mutation';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';

export class CouponPolicyService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }
  public async createCouponPolicies(
    params: CreateCouponPolicyRequestParam[],
    couponId: number
  ): Promise<void> {
    for (const param of params) {
      await this.createCouponPolicy(param, couponId);
    }
  }
  public async deleteCouponPolicies(
    params: CouponPolicyRequestParam[],
    couponId: number
  ): Promise<void> {
    for (const param of params) {
      await this.deleteCouponPolicy(param, couponId);
    }
  }
  private async createCouponPolicy(
    param: CreateCouponPolicyRequestParam,
    couponId: number
  ): Promise<CouponAPIResponse> {
    param.couponId = couponId;
    const createCouponPolicyResult = await this.apollo.mutate({
      mutation: CREATE_COUPON_POLICY_MUTATION,
      variables: {
        param: param,
      },
    });
    return createCouponPolicyResult.data.createCouponPolicy;
  }

  public async deleteCouponPolicy(
    param: CouponPolicyRequestParam,
    couponId: number
  ): Promise<CouponAPIResponse> {
    param.couponId = couponId;
    const deleteCouponPolicyResult = await this.apollo.mutate({
      mutation: DELETE_COUPON_POLICY_MUTATION,
      variables: {
        param: param,
      },
    });
    return deleteCouponPolicyResult.data.deleteCouponPolicy;
  }
}
