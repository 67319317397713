






















































































import Vue from 'vue';
import { ApolloQueryResult } from 'apollo-client';
import { DISPLAY_CATEGORY_LIST_QUERY } from '@/domain/category/display/queries/query';
import { DisplayCategoryConnection } from '@/domain/category/display/model/DisplayCategoryGraphqltype';
import { ListingLeafCategory } from '@/domain/category/model/ListingLeafCategory';
import { displayCategoryConvertService } from '@/domain/category/display/service/DisplayCategoryConvertService';
import InfoLabel from '@/components/Labels/InfoLabel.vue';
import { DisplayCategory } from '@/common/model/Category';
import { Table, TableColumn } from 'element-ui';
import { CategoryFilter } from '@/domain/category/model/CategoryFilter';
import { ContainerMixin } from '@/common/mixin/containerMixin';

export default ContainerMixin.extend({
  name: 'DisplayCategorySearchByName',
  components: {
    InfoLabel,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    checkedList: {
      type: Array,
    },
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      totalRows: 0,
      name: '',
      searchName: '',
      categories: { edges: [] },
      selectedCategories: [] as ListingLeafCategory[],
      listingLeafCategories: [] as ListingLeafCategory[],
      checkedCategoryList: [],
      allChecked: false,
    };
  },
  methods: {
    searchCategoriesByName(): void {
      this.listingLeafCategories = [];
      this.searchName = this.name;
    },
    showPath(paths: string[]): string {
      return paths.join('>');
    },
    addCategory(category: ListingLeafCategory): void {
      this.selectedCategories.push(category);
      this.$emit('input', this.selectedCategories);
    },
    deleteCategoryById(categoryId: string): void {
      const index = this.selectedCategories.findIndex(
        (category: ListingLeafCategory) => category.id === categoryId
      );
      this.selectedCategories.splice(index, 1);
      this.$emit('input', this.selectedCategories);
    },
    pageClick(bvEvent: any, page: number): void {
      console.log('page clicked!', page);
    },
    checkedAllCategory(event: boolean): void {
      this.categoryListCheck(event);
      this.$emit('changeSelectList', this.$data.checkedCategoryList);
    },
    categoryListCheck(value: boolean): void {
      this.$data.listingLeafCategories.map(
        (displayCategory: DisplayCategory & { checked: boolean }) => {
          if (value) {
            if (!this.existDuplicateRow(displayCategory)) {
              this.$data.checkedCategoryList.push(displayCategory);
            }
          } else {
            this.removeFromCheckedList(displayCategory);
          }

          displayCategory.checked = value;
        }
      );
    },
    addList(
      event: any,
      displayCategory: DisplayCategory & { checked?: boolean }
    ): void {
      displayCategory.checked = event;
      if (event) {
        this.$data.checkedCategoryList.push(displayCategory);
      } else {
        this.removeFromCheckedList(displayCategory);
      }

      this.$emit('changeSelectList', this.$data.checkedCategoryList);
    },
    removeFromCheckedList(
      checkedDisplayCategory: DisplayCategory & { checked?: boolean }
    ): void {
      const index = this.$data.checkedCategoryList.findIndex(
        (displayCategory: DisplayCategory) => {
          return displayCategory.id === checkedDisplayCategory.id;
        }
      );
      this.$data.checkedCategoryList.splice(index, 1);
    },
    existDuplicateRow(
      displayCategory: DisplayCategory & { checked?: boolean }
    ): boolean {
      return this.$data.checkedCategoryList.find(
        (row: DisplayCategory & { checked?: boolean }) => {
          return row.id === displayCategory.id;
        }
      );
    },
  },
  apollo: {
    hosts: {
      query: DISPLAY_CATEGORY_LIST_QUERY,
      variables(): {
        page: number;
        size: number;
        filter: CategoryFilter;
      } {
        return {
          page: this.page,
          size: this.perPage,
          filter: {
            likeLabelName: this.searchName,
          },
        };
      },
      result(
        result: ApolloQueryResult<{
          category: { displays: DisplayCategoryConnection };
        }>
      ): void {
        let checkedCount = 0;
        this.totalRows = result.data.category.displays.totalCount;

        this.$data.listingLeafCategories =
          result.data.category.displays.edges.map((edge: any) => {
            const displayCategory: ListingLeafCategory =
              displayCategoryConvertService.convertToListingLeafCategory(
                edge.node
              );

            const checkStatus =
              this.$data.checkedCategoryList.findIndex(
                (checkCategory: { id: number }) => {
                  return checkCategory.id.toString() === displayCategory.id;
                }
              ) !== -1;

            if (checkStatus) {
              checkedCount++;
            }

            return {
              id: displayCategory.id,
              title: displayCategory.name,
              depth: displayCategory.depth,
              checked: checkStatus,
            };
          });

        this.$data.allChecked =
          checkedCount === this.$data.listingLeafCategories.length;

        console.log(this.$data.checkedCategoryList);
      },
      skip(): boolean {
        return this.searchName === '';
      },
      update: data => data.category.displayLeaves,
      fetchPolicy: 'no-cache',
    },
  },
});
