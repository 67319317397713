import {
  CouponAPIResponse,
  CouponForm,
  CreateCouponRequestParam,
  UpdateCouponRequestParam,
} from '../model/coupon';
import { CouponPolicy } from '@/domain/coupon/model/CouponPolicy';
import { CouponKeyword } from '@/domain/coupon/model/CouponKeyword';
import { convertCouponKeywordToRequestPram } from '@/domain/coupon/util/couponKeywordConverter';
import { convertCouponPolicyToRequestParam } from '@/domain/coupon/util/couponPolicyConverter';

export const convertFormToCreateRequestParam = (
  form: CouponForm,
  keywords?: CouponKeyword[],
  policies?: CouponPolicy[]
): CreateCouponRequestParam => {
  if (form.usableTermElement.usableDays && form.usableTermElement.usableTerm) {
    throw new Error(
      '사용 가능 기간과 발급일로부터 사용일 중 하나만 입력해야합니다.'
    );
  }

  return {
    id: form.id,
    title: form.title,
    applyType: form.applyType,
    calculatingElement: {
      calculatingType: form.calculatingElement.calculatingType,
      calculatingValue: Number(form.calculatingElement.calculatingValue),
    },
    catchphrase: form.catchphrase,
    description: form.description,
    hostBurdenRatio: Number(form.hostBurdenRatio),
    issuableCountPerUser: Number(form.issuableCountPerUser) || null,
    issuableTerm: {
      startedAt: Number(form.issuableTerm.startedAt),
      endedAt: Number(form.issuableTerm.endedAt),
    },
    issueType: form.issueType,
    maxBenefit: Number(form.maxBenefit) || null,
    maxIssuableCount: Number(form.maxIssuableCount) || null,
    minApplicationAmount: Number(form.minApplicationAmount),
    usableTermElement: {
      usableDays: form.usableTermElement.usableDays
        ? Number(form.usableTermElement.usableDays)
        : null,
      usableTerm: form.usableTermElement.usableTerm
        ? {
            startedAt: Number(form.usableTermElement.usableTerm.startedAt),
            endedAt: Number(form.usableTermElement.usableTerm.endedAt),
          }
        : null,
    },
    useType: form.useType,
    duplicateUseAllowed: form.duplicateUseAllowed,
    exposed: form.exposed,
    keywords: keywords
      ?.map(i => convertCouponKeywordToRequestPram(i))
      .map(i => i.keyword),
    policies: policies?.map(i => convertCouponPolicyToRequestParam(i)),
  } as CreateCouponRequestParam;
};

export const convertFormToUpdateRequestParam = (
  form: CouponForm,
  keywords?: CouponKeyword[],
  policies?: CouponPolicy[]
): UpdateCouponRequestParam => {
  if (form.usableTermElement.usableDays && form.usableTermElement.usableTerm) {
    throw new Error(
      '사용 가능 기간과 발급일로부터 사용일 중 하나만 입력해야합니다.'
    );
  }

  return {
    couponId: Number(form.id),
    title: form.title,
    applyType: form.applyType,
    calculatingElement: {
      calculatingType: form.calculatingElement.calculatingType,
      calculatingValue: Number(form.calculatingElement.calculatingValue),
    },
    catchphrase: form.catchphrase,
    description: form.description,
    hostBurdenRatio: Number(form.hostBurdenRatio),
    issuableCountPerUser: Number(form.issuableCountPerUser) || null,
    issuableTerm: {
      startedAt: Number(form.issuableTerm.startedAt),
      endedAt: Number(form.issuableTerm.endedAt),
    },
    issueType: form.issueType,
    maxBenefit: Number(form.maxBenefit) || null,
    maxIssuableCount: Number(form.maxIssuableCount) || null,
    minApplicationAmount: Number(form.minApplicationAmount),
    usableTermElement: {
      usableDays: form.usableTermElement.usableDays
        ? Number(form.usableTermElement.usableDays)
        : null,
      usableTerm: form.usableTermElement.usableTerm
        ? {
            startedAt: Number(form.usableTermElement.usableTerm.startedAt),
            endedAt: Number(form.usableTermElement.usableTerm.endedAt),
          }
        : null,
    },
    keywords: keywords
      ?.map(i => convertCouponKeywordToRequestPram(i))
      .map(i => i.keyword),
    policies: policies?.map(i => convertCouponPolicyToRequestParam(i)),
  } as UpdateCouponRequestParam;
};

export const convertAPIResponseToForm = (
  apiResponse: CouponAPIResponse
): CouponForm => {
  const couponForm: CouponForm = {
    id: apiResponse.id,
    title: apiResponse.title,
    applyType: apiResponse.applyType,
    calculatingElement: {
      calculatingType: apiResponse.calculatingElement.calculatingType,
      calculatingValue: apiResponse.calculatingElement.calculatingValue,
    },
    catchphrase: apiResponse.catchphrase,
    description: apiResponse.description,
    hostBurdenRatio: apiResponse.hostBurdenRatio,
    issuableCountPerUser: apiResponse.issuableCountPerUser,
    issuableTerm: {
      startedAt: apiResponse.issuableTerm.startedAt,
      endedAt: apiResponse.issuableTerm.endedAt,
    },
    issueType: apiResponse.issueType,
    keywords: apiResponse.keywords,
    policies: apiResponse.policies,
    maxBenefit: apiResponse.maxBenefit,
    maxIssuableCount: apiResponse.maxIssuableCount,
    minApplicationAmount: apiResponse.minApplicationAmount,
    usableTermElement: {
      usableDays: apiResponse.usableTermElement.usableDays,
      usableTerm: apiResponse.usableTermElement.usableTerm,
    },
    issuedCount: apiResponse.issuedCount,
    usedCount: apiResponse.usedCount,
    useType: apiResponse.useType,
    duplicateUseAllowed: apiResponse.duplicateUseAllowed,
    exposed: apiResponse.exposed,
  };
  return couponForm;
};
