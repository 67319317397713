import { ListingLeafCategory } from '@/domain/category/model/ListingLeafCategory';
import { DisplayCategory } from '@/domain/category/display/model/DisplayCategory';

class DisplayCategoryConvertService {
  private buildPaths(item: DisplayCategory, paths: string[]): string[] {
    if (item && item.label.name) {
      paths.splice(0, 0, item.label.name);
    }
    if (item.parent) {
      this.buildPaths(item.parent, paths);
    }
    return paths;
  }

  public convertToListingLeafCategory(
    category: DisplayCategory
  ): ListingLeafCategory {
    return {
      id: category.id,
      name: category.label.name,
      depth: category.depth,
      paths: this.buildPaths(category, []),
    };
  }
}

export const displayCategoryConvertService =
  new DisplayCategoryConvertService();
