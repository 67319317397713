export enum CouponKeywordState {
  REGISTERED = 'REGISTERED',
  UNREGISTERED = 'UNREGISTERED',
  DELETED = 'DELETED',
}

export interface CouponKeyword {
  couponId?: number;
  keyword: string;
  status: CouponKeywordState;
}

export interface CouponKeywordRequestParam {
  couponId?: number;
  keyword: string;
}
