













































import Vue from 'vue';
import InlineInput from '@/components/Forms/InlineInput.vue';
import Callout from '@/components/Labels/Callout.vue';
import InfoLabel from '@/components/Labels/InfoLabel.vue';
import {
  CouponKeyword,
  CouponKeywordState,
} from '@/domain/coupon/model/CouponKeyword';

export default Vue.extend({
  name: 'CouponKeywordList',
  components: {
    InlineInput,
    Callout,
    InfoLabel,
  },
  props: {
    couponId: {
      type: Number,
      required: true,
    },
    value: {
      type: Array,
    },
  },
  data() {
    return {
      newKeyword: '',
      showErrorMessageTime: 0,
      errorMessage: '',
      keywords: [] as CouponKeyword[],
    };
  },
  computed: {
    couponKeywords(): CouponKeyword[] {
      return this.keywords.filter(
        (keyword: CouponKeyword) => keyword.status !== 'DELETED'
      );
    },
  },
  created(): void {
    this.keywords = this.value as CouponKeyword[];
  },
  methods: {
    showErrorMessage(message: string): void {
      this.showErrorMessageTime = 5;
      this.errorMessage = message.replace('GraphQL error:', '');
    },
    countDownChanged(showErrorMessageTime: number): void {
      this.showErrorMessageTime = showErrorMessageTime;
    },
    addCouponKeyword() {
      this.validateKeyword(this.newKeyword);

      this.keywords.push({
        keyword: this.newKeyword,
        status: CouponKeywordState.UNREGISTERED,
      });
    },
    deleteCouponKeyword(keywordToBeDeleted: string) {
      const keyword = this.keywords.find(i => i.keyword === keywordToBeDeleted);
      if (keyword) {
        keyword.status = CouponKeywordState.DELETED;
      }
    },

    validateKeyword(keyword: string) {
      if (keyword.length < 2) {
        this.showErrorMessage('최소 2자 이상의 키워드만 추가 가능합니다.');
        throw new Error('최소 2자 이상의 키워드만 추가 가능합니다.');
      }

      if (this.keywords.map(i => i.keyword).includes(keyword)) {
        this.showErrorMessage('중복된 키워드 입니다.');
        throw new Error('중복 키워드 입니다.');
      }
    },
  },
});
