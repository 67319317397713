





































































































import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { Table, TableColumn } from 'element-ui';
import Instant from '@/components/Labels/Instant.vue';
import IssuedCouponFilter from '../components/IssuedCouponFilter.vue';
import { ISSUED_COUPON_LIST_QUERY } from '../queries/query';
import {
  IssuedCouponAPIResponse,
  IssuedCouponConnection,
  IssuedCouponEdge,
  IssuedCouponFilterType,
  WithDrawIssuedCouponRequestInput,
} from '../model/issuedCoupon';
import Vue from 'vue';
import { IssuedCouponService } from '@/domain/coupon/service/IssuedCouponService';
import { apolloClient } from '@/apolloClient';
import { IssuedCouponStatus } from '@/domain/coupon/model/IssuedCouponStatus';

interface IssuedCouponsRequestParam {
  page: number;
  size: number;
  filter: IssuedCouponFilterType; // TODO: 타입 지정 필요함
}

const issuedCouponService = new IssuedCouponService(apolloClient);

export default Vue.extend({
  name: 'IssuedCouponList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    IssuedCouponFilter,
    Instant,
  },
  data() {
    return {
      coupon: { issuedCoupons: { edges: [] as IssuedCouponEdge[] } },
      page: this.$route.query.page || 1,
      size: this.$route.query.size || 10,
      totalRows: 0,
      usableTerm: null,
      used: null,
      status: '' as IssuedCouponStatus,
      userId: null,
      usedOption: [
        {
          label: '사용됨',
          value: true,
        },
        {
          label: '사용안됨',
          value: false,
        },
      ],
      filter: {
        usableTerm: null,
        used: null,
      } as IssuedCouponFilterType,
    };
  },
  computed: {
    issuedCoupons(): IssuedCouponAPIResponse[] {
      return this.coupon.issuedCoupons.edges.map(edge => edge.node);
    },
  },
  created() {
    if (this.$route.query.couponId) {
      this.$data.filter.couponId = Number(this.$route.query.couponId);
    }
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pageClick(bvEvent: any, page: number): void {
      console.log('page clicked!', page);
      if (this.$route.path !== `/coupon/list?page=${page}&size=${this.size}`) {
        this.$router.push(
          `/coupon/list/issued/list?page=${page}&size=${this.size}`
        );
      }
    },
    isUsed(used: boolean): string {
      if (used) {
        return 'danger';
      } else {
        return 'primary';
      }
    },
    addFilter(filter: IssuedCouponFilterType): void {
      this.filter = {
        usableTerm: filter.usableTerm ? filter.usableTerm : null,
        used: filter.used,
        status: filter.status,
      };

      if (filter.userId) {
        this.filter.userId = Number(filter.userId);
      }

      if (filter.couponId) {
        this.filter.couponId = Number(filter.couponId);
      }

      console.log(this.filter);
    },
    async withdrawalCoupon(
      issuedCoupon: IssuedCouponAPIResponse
    ): Promise<void> {
      this.$modal.show(
        {
          title: '쿠폰 회수!',
          message: '쿠폰을 회수 하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            const param: WithDrawIssuedCouponRequestInput = {
              issuedCouponId: Number(issuedCoupon.id),
            };
            await issuedCouponService.withdrawIssuedCoupon(param);
            issuedCoupon.used = true;
            issuedCoupon.status = IssuedCouponStatus.WITHDRAW;

            this.$modal.show({
              title: '쿠폰 회수 완료!',
              message: '쿠폰 회수를 완료했습니다!',
              type: 'success',
            });
          } catch (err) {
            this.$modal.show({
              title: '쿠폰 회수 실패!',
              message: err.message.replace('GraphQL error:', ''),
              type: 'info',
            });
          }
        },
        () => {
          return;
        }
      );
    },
  },
  apollo: {
    coupon: {
      query: ISSUED_COUPON_LIST_QUERY,
      variables(): IssuedCouponsRequestParam {
        return {
          page: Number(this.page),
          size: Number(this.size),
          filter: this.filter,
        };
      },
      error(e: ApolloError): void {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{
          coupon: { issuedCoupons: IssuedCouponConnection };
        }>
      ): void {
        console.log(
          'result.data.coupon.issuedCoupons',
          result.data.coupon.issuedCoupons
        );
        this.totalRows = result.data.coupon.issuedCoupons.totalCount;
      },
    },
  },
});
