





























































































































































































import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { BULK_ISSUANCE_LIST, COUPON_QUERY } from '../queries/query';
import Spinner from '@/components/Spinner.vue';
import CouponForm from '../components/CouponForm.vue';
import CouponKeywordList from '../components/CouponKeywordList.vue';
import {
  CouponAPIResponse,
  CouponForm as CouponFormType,
  CouponUseType,
} from '../model/coupon';
import { CouponKeyword, CouponKeywordState } from '../model/CouponKeyword';
import { CouponPolicy, CouponPolicyState } from '../model/CouponPolicy';
import { CouponUseCaseService } from '../service/CouponUseCaseService';
import { CouponService } from '../service/CouponService';
import { CouponKeywordService } from '../service/CouponKeywordService';
import { CouponPolicyService } from '../service/CouponPolicyService';
import { convertAPIResponseToForm } from '../util/couponConverter';

import { apolloClient } from '@/apolloClient';
import CouponPolicyRouter from '@/domain/coupon/components/CouponPolicyRouter.vue';
import IssuedCouponPushList from '@/domain/coupon/components/IssuedCouponPushList.vue';
import { Edge } from '@frientrip/domain';
import {
  BulkIssuance,
  BulkIssuanceDetailState,
  BulkIssuanceState,
  CouponBulkIssuanceParamInput,
} from '@/domain/coupon/model/bulkIssuance';
import { IssuanceCouponService } from '@/domain/coupon/service/IssuanceCouponService';
import { ContainerMixin } from '@/common/mixin/containerMixin';

const couponService = new CouponService(apolloClient);
const couponKeywordService = new CouponKeywordService(apolloClient);
const couponPolicyService = new CouponPolicyService(apolloClient);
const couponUseCaseService = new CouponUseCaseService(
  couponService,
  couponKeywordService,
  couponPolicyService
);
const issuanceCouponService = new IssuanceCouponService(apolloClient);

interface Connection<T> {
  totalCount: number;
  edges: Edge<T>[];
}

export default ContainerMixin.extend({
  name: 'CouponDetail',
  components: {
    IssuedCouponPushList,
    Spinner,
    // InfoLabel,
    CouponKeywordList,
    CouponPolicyRouter,
    CouponForm,
  },
  data() {
    return {
      couponId: 0,
      coupon: {
        applyType: 'CART',
        calculatingElement: {
          calculatingType: 'PERCENT',
          calculatingValue: '',
        },
        catchphrase: '',
        description: '',
        hostBurdenRatio: 0,
        issuableCountPerUser: '',
        issuableTerm: {
          startedAt: '',
          endedAt: '',
        },
        issueType: 'DOWNLOAD',
        maxBenefit: '',
        maxIssuableCount: '',
        minApplicationAmount: 0,
        title: '',
        usableTermElement: {
          usableDays: null,
          usableTerm: null,
        },
        usedCount: 0,
        issuedCount: 0,
        keywords: [],
        policies: [],
        useType: CouponUseType.NORMAL,
        duplicateUseAllowed: false,
        exposed: true,
        usableType: 'usableDays',
      } as CouponFormType,
      options: [
        { value: 'CART', text: '장바구니' },
        { value: 'OPTION', text: '옵션' },
      ],
      keywords: [] as CouponKeyword[],
      policies: [] as CouponPolicy[],
      loading: true,
      usableType: 'usableDays',
      showErrorMessageTime: 0,
      errorMessage: '',
      issueCouponToIds: '',
      page: 1,
      size: 10,
      bulkIssuanceList: [],
      issuanceCouponTotalCount: 0,
    };
  },
  computed: {
    couponDetailTitle(): string {
      if (this.$data.couponId > 0) {
        return this.$data.coupon.title;
      } else {
        return '쿠폰 등록';
      }
    },
  },
  watch: {
    policies(): CouponPolicy[] {
      return this.policies;
    },
  },
  mounted() {
    if (!this.$route.params.id) {
      this.loading = false;
    }
  },
  methods: {
    showErrorMessage(message: string): void {
      this.$data.showErrorMessageTime = 5;
      this.$data.errorMessage = message.replace('GraphQL error:', '');
    },
    countDownChanged(showErrorMessageTime: number): void {
      this.$data.showErrorMessageTime = showErrorMessageTime;
    },
    async createCoupon(): Promise<void> {
      if (this.coupon.title.length < 2) {
        return this.$modal.show({
          title: '쿠폰 타이틀을 입력하세요.',
          html: '쿠폰 타이틀을 2자 이상 입력해 주세요.',
          type: 'warning',
        });
      }

      if (this.coupon.description.length === 0) {
        return this.$modal.show({
          title: '쿠폰에 대한 설명을 입력하세요.',
          html: '입력한 쿠폰 설명이 없습니다.',
          type: 'warning',
        });
      }

      if (this.coupon.calculatingElement.calculatingValue === '') {
        return this.$modal.show({
          title: '쿠폰에 대한 설명을 입력하세요.',
          html: '할인율/할인금액을 입력하세요.',
          type: 'warning',
        });
      }

      if (this.coupon.issueType === 'DOWNLOAD') {
        if (this.coupon.issuableCountPerUser === '') {
          return this.$modal.show({
            title: '발급 가능 갯수를 입력하세요.',
            html: '유저당 발급 가능 갯수를 입력하세요.',
            type: 'warning',
          });
        }

        if (this.coupon.maxIssuableCount === '') {
          return this.$modal.show({
            title: '발급 가능 갯수를 입력하세요.',
            html: '최대 발급 가능 갯수를 입력하세요.',
            type: 'warning',
          });
        }
      }

      if (
        this.coupon.issuableTerm.startedAt === '' ||
        this.coupon.issuableTerm.endedAt === ''
      ) {
        return this.$modal.show({
          title: '발급 가능기간을 입력하세요.',
          html: '시작일/종료일을 입력하세요.',
          type: 'warning',
        });
      }

      if (
        this.coupon.issuableTerm.endedAt < this.coupon.issuableTerm.startedAt
      ) {
        return this.$modal.show({
          title: '발급 가능기간을 입력하세요.',
          html: '종료일이 시작일 이전입니다.',
          type: 'warning',
        });
      }

      if (this.$data.coupon.usableType === 'usableTerm') {
        if (
          !this.$data.coupon.usableTermElement.usableTerm?.startedAt ||
          !this.$data.coupon.usableTermElement.usableTerm?.endedAt
        ) {
          return this.$modal.show({
            title: '유효 기간을 입력하세요.',
            html: '시작일/종료일을 입력하세요.',
            type: 'warning',
          });
        }

        if (
          this.$data.coupon.usableTermElement.usableTerm.endedAt <
          this.$data.coupon.usableTermElement.usableTerm.startedAt
        ) {
          return this.$modal.show({
            title: '유효 기간을 입력하세요.',
            html: '종료일이 시작일 이전입니다.',
            type: 'warning',
          });
        }
      }

      if (this.$data.coupon.usableType === 'usableDays') {
        if (!this.$data.coupon.usableTermElement.usableDays) {
          return this.$modal.show({
            title: '유효 기간을 입력하세요.',
            html: '사용 가능 일수를 입력하세요.',
            type: 'warning',
          });
        }
      }

      if (
        !this.$data.policies?.some(function (policy: {
          status: string;
          allowed: boolean;
        }) {
          return policy.allowed === true && policy.status !== 'DELETED';
        })
      ) {
        return this.$modal.show(
          {
            title: '전체 공개 쿠폰 등록',
            message:
              '화이트 리스트가 지정되지 않은 경우, \n전체 공개 상태로 쿠폰이 생성됩니다. \n진행 하시겠습니까?',
            type: 'danger',
            showCancelButton: true,
          },
          async () => {
            try {
              const couponId =
                await couponUseCaseService.createCouponWithKeywordsAndPolicies(
                  this.getCreateParam(),
                  this.$data.keywords,
                  this.$data.policies
                );

              await this.$router.push(`/coupon/list/${couponId}`);
            } catch (error) {
              console.error(error);
              this.showErrorMessage(error.message);
            }
          }
        );
      }

      return this.$modal.show(
        {
          title: '등록',
          message: '쿠폰을 등록하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        async () => {
          try {
            const couponId =
              await couponUseCaseService.createCouponWithKeywordsAndPolicies(
                this.getCreateParam(),
                this.$data.keywords,
                this.$data.policies
              );

            await this.$router.push(`/coupon/list/${couponId}`);
          } catch (error) {
            console.error(error);
            this.showErrorMessage(error.message);
          }
        }
      );
    },

    async updateCoupon(): Promise<void> {
      this.$modal.show(
        {
          title: '수정',
          message: '수정 하시겠습니까?',
          type: 'info',
          showCancelButton: true,
        },
        async () => {
          try {
            await couponUseCaseService.updateCouponWithKeywordsAndPolicies(
              this.getCreateParam(),
              this.$data.keywords,
              this.$data.policies
            );

            await this.$router.go(0);
          } catch (error) {
            console.error(error);
            this.showErrorMessage(error.message);
          }
        }
      );
    },

    async deleteCoupon(): Promise<void> {
      this.$modal.show(
        {
          title: '삭제',
          message: '정말로 삭제 하시겠습니까?',
          type: 'warning',
          showCancelButton: true,
        },
        async () => {
          await this.delete();
        }
      );
    },

    async changeCouponPolicies(policies: CouponPolicy[]) {
      this.$data.policies = policies;
    },
    async issueCoupon(): Promise<void> {
      const couponId = Number(this.$data.couponId);
      const ids = this.$data.issueCouponToIds
        .split(/[\s,\n]+/)
        .filter((id: string) => id !== '')
        .map((id: string) => {
          return Number(id);
        });
      console.log(ids);
      const checkedNumberArray = ids.filter((id: string) => isNaN(Number(id)));

      if (ids.length === 0) {
        this.$modal.show({
          title: '발급 실패!',
          message: '발급 받을 유저 ID를 입력해주세요.',
          type: 'warning',
        });

        return;
      }

      if (checkedNumberArray.length > 0) {
        this.$modal.show({
          title: '발급 실패!',
          message: '발급 id를 확인해주세요.',
          type: 'warning',
        });

        return;
      }

      if (ids.length > 1000) {
        this.showErrorAlert(
          { message: '1000명 이상의 대상은 푸시 발송을 보낼 수 없습니다.' },
          '발송 실패!'
        );

        return;
      }

      try {
        const bulkIssuanceParamInput: CouponBulkIssuanceParamInput = {
          couponId,
          userIds: ids,
        };

        const response = await issuanceCouponService.issueCouponByIdWithUserIds(
          bulkIssuanceParamInput
        );

        if (response.status === BulkIssuanceState.FAIL) {
          const errorMessage = this.errorMessageToBulkIssuanceDetailStatus(
            response.detailStatus
          );

          this.showErrorAlert({ message: errorMessage }, '쿠폰 발급 실패!');

          await this.$apollo.queries.bulkIssuanceCouponList.refetch();

          return;
        }

        this.$modal.show(
          {
            title: '쿠폰 발급 진행!',
            html: '쿠폰 발급 요청이 완료되었습니다.<br/>발급 성공 여부는 발급 내역 확인 및 푸시 발송 > 발급 상태에서 확인 가능합니다.',
            type: 'success',
          },
          () => {
            (this as any).$router.go();
          }
        );
      } catch (err) {
        this.showErrorAlert(err, '쿠폰 발급 실패!');
      }
    },
    goToCouponList(): void {
      this.$router.push('/coupon/list');
    },
    getCreateParam(): CouponFormType {
      this.$data.coupon.maxBenefit =
        this.$data.coupon.maxBenefit === ''
          ? null
          : Number(this.$data.coupon.maxBenefit);

      this.$data.coupon.issuableCountPerUser =
        this.$data.coupon.issuableCountPerUser == ''
          ? null
          : Number(this.$data.coupon.issuableCountPerUser);

      this.$data.coupon.maxIssuableCount =
        this.$data.coupon.maxIssuableCount == ''
          ? null
          : Number(this.$data.coupon.maxIssuableCount);
      return this.$data.coupon;
    },
    errorMessageToBulkIssuanceDetailStatus(
      detailState: BulkIssuanceDetailState
    ): string {
      switch (detailState) {
        case BulkIssuanceDetailState.ETC_FAIL:
          return '발급에 실패했습니다.';
        case BulkIssuanceDetailState.NORMAL:
          return '정상적으로 발급되었습니다.';
        case BulkIssuanceDetailState.EXCEEDED_ISSUE_COUNT_FAIL:
          return '최대 발급 가능한 수량을 초과했습니다.';
        case BulkIssuanceDetailState.INVALID_ISSUABLE_TERM_FAIL:
          return '발급 가능 기간이 아닙니다.';
        default:
          return '발급에 실패했습니다.';
      }
    },
    async delete(): Promise<void> {
      try {
        const param = {
          couponId: Number(this.$data.couponId),
        };
        await couponService.deleteCoupon(param);
        await this.$router.push('/coupon/list');
      } catch (error) {
        console.error(error);
        this.showErrorMessage(error.message);
      }
    },
  },
  apollo: {
    couponDetail: {
      query: COUPON_QUERY,
      variables() {
        return {
          id: Number(this.$route.params.id) || 0,
        };
      },
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorAlert(error.message);
      },
      async result(result): Promise<void> {
        const coupon: CouponAPIResponse = result.data?.coupon.coupon;
        if (coupon) {
          this.$data.couponId = coupon.id;
          this.$data.coupon = convertAPIResponseToForm(coupon);
          this.$data.keywords = coupon.keywords.map(i => {
            return {
              keyword: i,
              status: CouponKeywordState.REGISTERED,
            } as CouponKeyword;
          });

          this.$data.policies = coupon.policies.map(i => {
            return {
              ...i,
              status: CouponPolicyState.REGISTERED,
            } as CouponPolicy;
          });
          this.loading = false;
        }
      },
      skip(): boolean {
        return !this.$route.params.id;
      },
      update: data => data.couponDetail,
    },
    bulkIssuanceCouponList: {
      query: BULK_ISSUANCE_LIST,
      variables() {
        return {
          filter: {
            couponId: Number(this.$data.couponId),
          },
          page: this.$data.page,
          size: this.$data.size,
        };
      },
      error(error: ApolloError): void {
        console.error(error);
        this.showErrorAlert(error.message);
      },
      result(
        result: ApolloQueryResult<{
          coupon: { bulkIssuanceList: Connection<BulkIssuance> };
        }>
      ): void {
        this.$data.bulkIssuanceList =
          result.data.coupon.bulkIssuanceList.edges.map(
            (edge: Edge<BulkIssuance>) => {
              return edge.node;
            }
          );

        this.$data.issuanceCouponTotalCount =
          result.data.coupon.bulkIssuanceList.totalCount;
      },
      skip(): boolean {
        return !this.$route.params.id || this.$route.params.id === '0';
      },
      update: data => data.bulkIssuanceCouponList,
    },
  },
});
