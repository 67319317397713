import gql from 'graphql-tag';

export const COUPON_LIST_QUERY = gql`
  query coupons($filter: CouponFilterInput, $page: Int, $size: Int) {
    coupon {
      coupons(filter: $filter, page: $page, size: $size) {
        edges {
          node {
            id
            applyType
            calculatingElement {
              calculatingType
              calculatingValue
            }
            catchphrase
            description
            issueType
            issuedCount
            issuableTerm {
              startedAt
              endedAt
            }
            keywords
            maxBenefit
            maxIssuableCount
            title
            usedCount
            usableTermElement {
              usableDays
              usableTerm {
                startedAt
                endedAt
              }
            }
          }
          cursor
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
      }
    }
  }
`;

export const COUPON_QUERY = gql`
  query coupon($id: Int!) {
    coupon {
      coupon(id: $id) {
        id
        applyType
        calculatingElement {
          calculatingType
          calculatingValue
        }
        catchphrase
        hostBurdenRatio
        description
        issuedCount
        issuableCountPerUser
        issuableTerm {
          startedAt
          endedAt
        }
        issueType
        keywords
        maxBenefit
        maxIssuableCount
        minApplicationAmount
        policies {
          id
          allowed
          coupon {
            id
          }
          reference {
            id
            type
          }
        }
        title
        usableTermElement {
          usableDays
          usableTerm {
            startedAt
            endedAt
          }
        }
        usedCount
        issuedCount
        useType
        duplicateUseAllowed
        exposed
      }
    }
  }
`;

export const ISSUED_COUPON_LIST_QUERY = gql`
  query issuedCoupons(
    $filter: IssuedCouponFilterInput
    $page: Int
    $size: Int
  ) {
    coupon {
      issuedCoupons(filter: $filter, page: $page, size: $size) {
        edges {
          node {
            id
            createdAt
            coupon {
              id
              title
              usedCount
            }
            used
            status
            userId
            usableTerm {
              startedAt
              endedAt
            }
          }
          cursor
        }
        totalCount
      }
    }
  }
`;

export const BULK_ISSUANCE_LIST = gql`
  query bulkIssuanceList(
    $filter: BulkIssuanceFilterInput!
    $page: Int
    $size: Int
  ) {
    coupon {
      bulkIssuanceList(filter: $filter, page: $page, size: $size) {
        edges {
          node {
            id
            coupon {
              id
              title
            }
            requestedUserCount
            issuedCount
            status
            detailStatus
            requestedAt
            appPushLogs {
              push {
                target {
                  id
                }
              }
            }
          }
        }
        totalCount
      }
    }
  }
`;

export const BULK_ISSUANCE_ITEM_LIST = gql`
  query bulkIssuanceItemList(
    $filter: BulkIssuanceItemFilterInput!
    $page: Int
    $size: Int
  ) {
    coupon {
      bulkIssuanceItemList(filter: $filter, page: $page, size: $size) {
        edges {
          node {
            id
            issuedCouponId
            status
            userId
            detailStatus
          }
        }
      }
    }
  }
`;

export const DOWNLOAD_COUPON_TARGET_LIST = gql`
  query couponTargetInfos(
    $filter: CouponTargetInfoFilter
    $page: Int
    $size: Int
  ) {
    coupon {
      couponTargetInfos(filter: $filter, page: $page, size: $size) {
        id
        targetType
        targetId
        status
        createdAt
        updatedAt
        coupon
        appPushTemplateId
      }
    }
  }
`;

export const DOWNLOAD_COUPON_TARGET_INFO = gql`
  query downloadCouponTargetInfo($id: ID!) {
    coupon {
      downloadCouponTargetInfo(id: $id) {
        id
        targetId
        targetType
        createdAt
        description
        downloadableCouponInfos {
          id
          coupon {
            id
            title
          }
          status
        }
        downloadableCoupons {
          id
          title
        }
        downloadCouponButtonInfos {
          id
          displayPosition
          buttonImage {
            id
            uri
          }
          status
        }
      }
    }
  }
`;

export const DOWNLOAD_COUPON_TARGET_INFOS = gql`
  query downloadCouponTargetInfos(
    $filter: DownloadCouponTargetInfoFilterInput
    $page: Int
    $size: Int
  ) {
    coupon {
      downloadCouponTargetInfos(filter: $filter, page: $page, size: $size) {
        edges {
          cursor
          node {
            id
            status
            targetId
            targetType
            createdAt
            updatedAt
            title
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  }
`;

export const MAGAZINE_QUERY = gql`
  query MagazineQuery($id: ID!, $width: Int!) {
    fripMagazine {
      magazine(id: $id) {
        id
        title
        catchphrase
        status
        magazineContent
        magazineBannerContents {
          id
          thumbnail(width: $width, fetchFormat: AUTO, quality: AUTO)
        }
        worker {
          id
          nickname
        }
        publishStartDate
      }
    }
  }
`;
