



















































































import Vue from 'vue';
import {
  CouponPolicy,
  CouponPolicyReferenceType,
  CouponPolicyState,
} from '@/domain/coupon/model/CouponPolicy';
import HostSearchList from '@/components/Host/HostSearchList.vue';
import ProductSearchList from '@/components/Product/ProductSearchList.vue';
import { Host } from '@/domain/host/models/host';
import { Product } from '@/domain/product/model/product/response/product';
import DisplayCategorySearchByName from '@/domain/category/display/components/DisplayCategorySearchByName.vue';
import { ListingLeafCategory } from '@/domain/category/model/ListingLeafCategory';

export default Vue.extend({
  name: 'CouponPolicyReferenceSearch',
  components: {
    HostSearchList,
    ProductSearchList,
    DisplayCategorySearchByName,
  },
  props: {
    value: {
      type: Array,
    },
    referenceType: {
      type: String,
    },
    allowed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: '',
      type: '' as CouponPolicyReferenceType,
      selectedCategories: [] as ListingLeafCategory[],
      selectedProducts: [] as Product[],
      selectedHosts: [] as Host[],
      selectedPolicies: [] as CouponPolicy[],
      showErrorMessageTime: 0,
      errorMessage: '',
      checkedList: [],
    };
  },
  computed: {
    policyListType(): string {
      if (this.allowed) {
        return '화이트 리스트';
      } else {
        return '블랙 리스트';
      }
    },
  },
  created() {
    console.log('this.referenceType :', this.referenceType);

    switch (this.referenceType) {
      case CouponPolicyReferenceType.CATEGORY:
        this.title = '카테고리';
        this.type = CouponPolicyReferenceType.CATEGORY;
        break;
      case CouponPolicyReferenceType.PRODUCT:
        this.title = '상품';
        this.type = CouponPolicyReferenceType.PRODUCT;
        break;
      case CouponPolicyReferenceType.HOST:
        this.title = '호스트';
        this.type = CouponPolicyReferenceType.HOST;
        break;
      default:
        return '';
    }
  },
  methods: {
    showErrorMessage(message: string): void {
      this.showErrorMessageTime = 5;
      this.errorMessage = message.replace('GraphQL error:', '');
    },
    countDownChanged(showErrorMessageTime: number): void {
      this.showErrorMessageTime = showErrorMessageTime;
    },
    isCategoryType(type: CouponPolicyReferenceType): boolean {
      return type == CouponPolicyReferenceType.CATEGORY;
    },
    isProductType(type: CouponPolicyReferenceType): boolean {
      return type == CouponPolicyReferenceType.PRODUCT;
    },
    isHostType(type: CouponPolicyReferenceType): boolean {
      return type == CouponPolicyReferenceType.HOST;
    },

    async addPolicy(): Promise<void> {
      console.log('this.type : ', this.type);

      switch (this.type) {
        case CouponPolicyReferenceType.CATEGORY: {
          const newPolicies = this.checkedList.map((category: any) => {
            return {
              allowed: this.allowed,
              reference: {
                id: category.id,
                type: CouponPolicyReferenceType.CATEGORY,
              },
              status: CouponPolicyState.UNREGISTERED,
            } as CouponPolicy;
          });

          this.selectedPolicies.push(...newPolicies);
          break;
        }

        case CouponPolicyReferenceType.PRODUCT: {
          const newPolicies = this.checkedList.map((product: Product) => {
            return {
              allowed: this.allowed,
              reference: {
                id: product.id,
                type: CouponPolicyReferenceType.PRODUCT,
              },
              status: CouponPolicyState.UNREGISTERED,
            } as CouponPolicy;
          });

          this.selectedPolicies.push(...newPolicies);
          break;
        }

        case CouponPolicyReferenceType.HOST: {
          const newPolicies = this.checkedList.map((host: any) => {
            return {
              allowed: this.allowed,
              reference: {
                id: host.id,
                type: CouponPolicyReferenceType.HOST,
              },
              status: CouponPolicyState.UNREGISTERED,
            } as CouponPolicy;
          });

          this.selectedPolicies.push(...newPolicies);
          break;
        }
      }

      this.$emit('addCouponPolicy', this.selectedPolicies);
    },
    updateCheckedList(list: any[]): void {
      this.$data.checkedList = list;
    },
    closeModal(): void {
      this.$emit('closeModal');
    },
  },
});
