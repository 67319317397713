import { CouponAPIResponse } from '@/domain/coupon/model/coupon';
import { CommonState } from '@frientrip/domain';
import { AppPushLog } from '@/common/model/AppPush';

export interface BulkIssuance {
  id: number;
  coupon: CouponAPIResponse;
  requestedUserCount: number;
  issuedCount: number;
  status: BulkIssuanceState;
  detailStatus: BulkIssuanceDetailState;
  appPushLogs: AppPushLog;
}

export enum BulkIssuanceState {
  ISSUING = 'ISSUING', //발급중
  REGISTER = 'REGISTER', //발급접수
  SUCCESS = 'SUCCESS', //정상발급
  FAIL = 'FAIL', //발급실패
}

export interface CouponBulkIssuanceParamInput {
  userIds: number[];
  couponId: number;
}

export enum BulkIssuanceDetailState {
  NORMAL = 'NORMAL',
  EXCEEDED_ISSUE_COUNT_FAIL = 'EXCEEDED_ISSUE_COUNT_FAIL',
  INVALID_ISSUABLE_TERM_FAIL = 'INVALID_ISSUABLE_TERM_FAIL',
  ETC_FAIL = 'ETC_FAIL',
}

export interface BulkIssuanceFilter {
  couponId?: number;
}

export interface BulkIssuanceItemFilter {
  bulkIssuanceId: number;
  status: CommonState;
}

export const BulkIssuanceStateLabel: {
  value: BulkIssuanceState;
  label: string;
}[] = [
  {
    value: BulkIssuanceState.FAIL,
    label: '실패',
  },
  {
    value: BulkIssuanceState.SUCCESS,
    label: '성공',
  },
  {
    value: BulkIssuanceState.REGISTER,
    label: '발급접수',
  },
  {
    value: BulkIssuanceState.ISSUING,
    label: '발급중',
  },
];

export const BulkIssuanceDetailStateLabel: {
  value: BulkIssuanceDetailState;
  label: string;
}[] = [
  {
    value: BulkIssuanceDetailState.ETC_FAIL,
    label: '기타 실패',
  },
  {
    value: BulkIssuanceDetailState.NORMAL,
    label: '정상',
  },
  {
    value: BulkIssuanceDetailState.EXCEEDED_ISSUE_COUNT_FAIL,
    label: '발급가능 수량초과 실패',
  },
  {
    value: BulkIssuanceDetailState.INVALID_ISSUABLE_TERM_FAIL,
    label: '미유효발급 가능기간 실패',
  },
];
