import {
  CouponAPIResponse,
  CouponsAndTotalCount,
  CouponsRequestParam,
  CreateCouponRequestParam,
  UpdateCouponRequestParam,
} from '../model/coupon';
import {
  CREATE_COUPON_MUTATION,
  DELETE_COUPON_MUTATION,
  UPDATE_COUPON_MUTATION,
} from '../queries/mutation';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { COUPON_LIST_QUERY } from '@/domain/coupon/queries/query';

interface CouponDeleteParam {
  couponId: number;
}

export class CouponService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  public async createCoupon(
    param: CreateCouponRequestParam
  ): Promise<CouponAPIResponse> {
    const createCouponResult = await this.apollo.mutate({
      mutation: CREATE_COUPON_MUTATION,
      variables: {
        param: param,
      },
    });
    return createCouponResult.data.createCoupon;
  }

  public async updateCoupon(
    param: UpdateCouponRequestParam
  ): Promise<CouponAPIResponse> {
    const updateCouponResult = await this.apollo.mutate({
      mutation: UPDATE_COUPON_MUTATION,
      variables: {
        param: param,
      },
    });
    return updateCouponResult.data.updateCoupon;
  }

  public async deleteCoupon(
    param: CouponDeleteParam
  ): Promise<CouponAPIResponse> {
    const deleteCouponResult = await this.apollo.mutate({
      mutation: DELETE_COUPON_MUTATION,
      variables: {
        param: param,
      },
    });
    return deleteCouponResult.data.deleteCoupon;
  }

  public async getCoupon(
    param: CouponsRequestParam
  ): Promise<CouponsAndTotalCount> {
    const couponConnection = await this.apollo.query({
      query: COUPON_LIST_QUERY,
      variables: {
        page: param.page,
        size: param.size,
        filter: param.filter,
      },
    });

    const couponTotalCount = couponConnection.data.coupon.coupons.totalCount;
    const coupons = couponConnection.data.coupon?.coupons.edges.map(
      (edge: { node: CouponAPIResponse }) => edge.node
    );

    return {
      totalCount: couponTotalCount,
      coupons,
    };
  }
}
