var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('content-card',{attrs:{"title":"발급된 쿠폰 목록","subtitle":_vm.totalRows.toString()},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('b-pagination',{staticClass:"mt-3",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.size,"align":"center","first-number":"","last-number":""},on:{"page-click":_vm.pageClick},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true}])},[_c('div',[_c('issued-coupon-filter',{staticClass:"my-3",on:{"addFilter":_vm.addFilter}})],1),_c('el-table',{ref:"table",staticClass:"table table-responsive table-flush table-striped",attrs:{"header-row-class-name":"thead-light","cell-style":{ height: '70px' },"data":_vm.issuedCoupons}},[_c('el-table-column',{attrs:{"label":"id","prop":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.id))]}}])}),_c('el-table-column',{attrs:{"label":"쿠폰 ID","prop":"coupon.id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.coupon.id))]}}])}),_c('el-table-column',{attrs:{"label":"발급일자","prop":"createdAt"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.$moment(row.createdAt).format('YYYY-MM-DD')))]}}])}),_c('el-table-column',{attrs:{"label":"쿠폰 이름","prop":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('a',{attrs:{"href":("/coupon/list/" + (row.coupon.id))}},[_vm._v(_vm._s(row.coupon.title))])]}}])}),_c('el-table-column',{attrs:{"label":"사용여부","prop":"used"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.used && row.status === 'ACTIVE')?_c('frip-badge',{attrs:{"type":"danger"}},[_vm._v(" 사용됨 ")]):(row.status === 'WITHDRAW')?_c('frip-badge',{staticClass:"bg-badge-color-grey",attrs:{"type":"danger"}},[_vm._v("회수됨")]):_c('frip-badge',{attrs:{"type":"primary"}},[_vm._v("사용가능")])]}}])}),_c('el-table-column',{attrs:{"label":"쿠폰 회수"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!row.used && row.status === 'ACTIVE')?_c('frip-button',{attrs:{"outline":"","size":"sm","type":"primary"},on:{"click":function($event){return _vm.withdrawalCoupon(row)}}},[_vm._v("쿠폰 회수")]):_c('div',[_vm._v("-")])]}}])}),_c('el-table-column',{attrs:{"label":"사용 가능 기간","prop":"used","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('instant',{attrs:{"at":row.usableTerm.startedAt,"short":""}}),_c('span',[_vm._v(" ~ ")]),_c('instant',{attrs:{"at":row.usableTerm.endedAt,"short":""}})]}}])}),_c('el-table-column',{attrs:{"label":"발급받은 유저","prop":"user"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('a',{attrs:{"href":("/user/list/" + (row.userId))}},[_vm._v(_vm._s(row.userId))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }