import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { CREATE_DOWNLOAD_COUPON_BUTTON_INFOS } from '@/domain/coupon/queries/mutation';
import {
  DownloadCouponTargetInfo,
  DownloadCouponTargetInfosInput,
} from '@/domain/coupon/model/coupon';

export class DownloadCouponService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  async createDownloadCouponTargetInfo(
    param: DownloadCouponTargetInfosInput
  ): Promise<DownloadCouponTargetInfo> {
    const createDownloadCouponTargetInfo = await this.apollo.mutate({
      mutation: CREATE_DOWNLOAD_COUPON_BUTTON_INFOS,
      variables: {
        param: param,
      },
    });
    console.log(
      `result create DownloadCoupon ${JSON.stringify(
        createDownloadCouponTargetInfo
      )}`
    );
    return createDownloadCouponTargetInfo.data.saveDownloadCouponTargetInfo;
  }
}
