import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import {
  DeleteIssuedCouponRequestInput,
  WithDrawAllIssuedCouponRequestInput,
  WithdrawAllIssuedCouponResponse,
  WithDrawIssuedCouponRequestInput,
} from '@/domain/coupon/model/issuedCoupon';
import {
  DELETE_ISSUED_COUPON,
  WITHDRAW_ALL_ISSUED_COUPON,
  WITHDRAW_ISSUED_COUPON,
} from '@/domain/coupon/queries/mutation';

export class IssuedCouponService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  async deleteIssuedCoupon(
    param: DeleteIssuedCouponRequestInput
  ): Promise<void> {
    const response = await this.apollo.mutate({
      mutation: DELETE_ISSUED_COUPON,
      variables: {
        param,
      },
    });
  }

  async withdrawIssuedCoupon(
    param: WithDrawIssuedCouponRequestInput
  ): Promise<void> {
    const response = await this.apollo.mutate({
      mutation: WITHDRAW_ISSUED_COUPON,
      variables: {
        param,
      },
    });
  }

  async withdrawAllIssuedCoupon(
    param: WithDrawAllIssuedCouponRequestInput
  ): Promise<WithdrawAllIssuedCouponResponse> {
    const response = await this.apollo.mutate({
      mutation: WITHDRAW_ALL_ISSUED_COUPON,
      variables: {
        param,
      },
    });
    return response.data as WithdrawAllIssuedCouponResponse;
  }
}
