















































































































































































import Vue from 'vue';
import PriceInput from '@/components/Forms/PriceInput.vue';
import PercentInput from '@/components/Forms/PercentInput.vue';
import {
  CouponForm as CouponFormType,
  CouponUseType,
} from '@/domain/coupon/model/coupon';

export default Vue.extend({
  name: 'CouponForm',
  components: {
    PriceInput,
    PercentInput,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    couponId: {
      type: Number,
      required: true,
    },
    issuedCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      hasIssuedCoupon: false,
      isNewCoupon: true,
      form: {
        applyType: 'CART',
        calculatingElement: {
          calculatingType: 'PERCENT',
          calculatingValue: '',
        },
        catchphrase: '',
        description: '',
        hostBurdenRatio: 0,
        issuedCount: '',
        issuableCountPerUser: '',
        issuableTerm: {
          startedAt: '',
          endedAt: '',
        },
        issueType: 'DOWNLOAD',
        maxBenefit: '',
        maxIssuableCount: '',
        minApplicationAmount: 0,
        title: '',
        usableTermElement: {
          usableDays: null,
          usableTerm: null,
        },
        usedCount: '',
        useType: 'NORMAL',
        duplicateUseAllowed: false,
        exposed: true,
        usableType: 'usableDays',
      },
      applyTypeOptions: [
        { value: 'CART', text: '장바구니' },
        { value: 'OPTION', text: '옵션' },
      ],
      calculatingTypeOptions: [
        {
          text: '퍼센트',
          value: 'PERCENT',
        },
        {
          text: '원',
          value: 'PRICE',
        },
      ],
      issueTypeOptions: [
        {
          text: '시리얼',
          value: 'SERIAL',
        },
        {
          text: '다운로드',
          value: 'DOWNLOAD',
        },
        {
          text: '키워드',
          value: 'KEYWORD',
        },
      ],
      usableTypeOptions: [
        {
          text: '발급일로부터 유효기간 지정',
          value: 'usableDays',
        },
        {
          text: '사용 가능 기간 지정',
          value: 'usableTerm',
        },
      ],
      loading: 0,
      usableType: 'usableDays',
      showErrorMessageTime: 0,
      errorMessage: '',
      prevUsableTerm: null,
      prevUsableDays: null,
      useTypeOptions: [
        {
          text: '상품 할인',
          value: 'NORMAL',
        },
        {
          text: '추가 할인',
          value: 'ADDITIONAL',
        },
      ],
      duplicateUseAllowedOptions: [
        {
          text: '중복 사용 가능',
          value: true,
          disabled: false,
        },
        {
          text: '중복 사용 불가능',
          value: false,
          disabled: false,
        },
      ],
      exposedOptions: [
        {
          text: '노출',
          value: true,
        },
        {
          text: '비노출',
          value: false,
        },
      ],
    };
  },
  computed: {
    calculatingTypeAppendMessage(): string {
      if (this.form.calculatingElement.calculatingType === 'PERCENT') {
        return '%';
      } else {
        return '원';
      }
    },
  },
  watch: {
    usableType: {
      immediate: false,
      handler(newValue: string): void {
        if (newValue === 'usableDays') {
          this.form.usableTermElement.usableDays = this.prevUsableDays;
          this.form.usableTermElement.usableTerm = null;
          this.form.usableType = 'usableDays';
          this.usableType = this.form.usableType;
        } else {
          this.form.usableTermElement.usableTerm = this.prevUsableTerm;
          this.form.usableTermElement.usableDays = null;
          this.form.usableType = 'usableTerm';
          this.usableType = this.form.usableType;
        }
      },
    },
    form: {
      immediate: false,
      handler(newValue: CouponFormType): void {
        if (newValue.useType === CouponUseType.NORMAL) {
          this.duplicateUseAllowedOptions = [
            {
              text: '중복 사용 가능',
              value: true,
              disabled: true,
            },
            {
              text: '중복 사용 불가능',
              value: false,
              disabled: false,
            },
          ];

          this.form.duplicateUseAllowed = false;
        } else {
          this.duplicateUseAllowedOptions = [
            {
              text: '중복 사용 가능',
              value: true,
              disabled: false,
            },
            {
              text: '중복 사용 불가능',
              value: false,
              disabled: false,
            },
          ];
        }
      },
      deep: true,
    },
  },
  created(): void {
    this.form = this.value;

    if (this.couponId > 0) {
      this.isNewCoupon = false;
    }
    this.usableType = this.form.usableTermElement.usableDays
      ? 'usableDays'
      : 'usableTerm';
    this.hasIssuedCoupon = Number(this.form.issuedCount) > 0;
    this.prevUsableTerm = this.form.usableTermElement.usableTerm;
    this.prevUsableDays = this.form.usableTermElement.usableDays;
  },
  methods: {
    onInput(): void {
      this.$emit('input', this.form);
    },
  },
});
