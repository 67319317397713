import {
  CouponKeyword,
  CouponKeywordRequestParam,
} from '@/domain/coupon/model/CouponKeyword';

export const convertCouponKeywordToRequestPram = (
  keyword: CouponKeyword
): CouponKeywordRequestParam => {
  return {
    couponId: keyword.couponId,
    keyword: keyword.keyword,
  };
};
