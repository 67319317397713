






















import { ContainerMixin } from '@/common/mixin/containerMixin';
import { Option, Select, Table, TableColumn } from 'element-ui';
import ImageUploadBox from '@/components/ImageUpload/ImageUploadBox.vue';

export default ContainerMixin.extend({
  name: 'DownloadCouponButtonImageUpload',
  components: {
    ImageUploadBox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    buttonImage: {
      type: Object,
    },
    position: {
      type: String,
    },
  },
  data(): {
    image: { id: string; url: string };
    displayPosition: string;
  } {
    return {
      image: this.buttonImage,
      displayPosition: this.position,
    };
  },
  methods: {
    closeModal(): void {
      this.$emit('close');
    },
    saveImage(): void {
      this.$emit('close', {
        buttonImage: this.image,
        displayPosition: this.displayPosition,
      });
    },
    initImage() {
      this.image = {
        id: '',
        url: '',
      };
    },
  },
});
