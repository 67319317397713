import { CouponAPIResponse } from '../model/coupon';
import {
  CREATE_COUPON_KEYWORD_MUTATION,
  DELETE_COUPON_KEYWORD_MUTATION,
} from '../queries/mutation';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';

interface CouponKeywordRequestParam {
  couponId?: number;
  keyword: string;
}

export class CouponKeywordService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }
  public async createCouponKeywords(
    params: CouponKeywordRequestParam[],
    couponId: number
  ): Promise<void> {
    for (const param of params) {
      await this.createCouponKeyword(param, couponId);
    }
  }
  public async deleteCouponKeywords(
    params: CouponKeywordRequestParam[],
    couponId: number
  ): Promise<void> {
    for (const param of params) {
      await this.deleteCouponKeyword(param, couponId);
    }
  }
  private async createCouponKeyword(
    param: CouponKeywordRequestParam,
    couponId: number
  ): Promise<CouponAPIResponse> {
    param.couponId = couponId;
    const createCouponKeywordResult = await this.apollo.mutate({
      mutation: CREATE_COUPON_KEYWORD_MUTATION,
      variables: {
        param: param,
      },
    });
    return createCouponKeywordResult.data.createCouponKeyword;
  }
  private async deleteCouponKeyword(
    param: CouponKeywordRequestParam,
    couponId: number
  ): Promise<CouponAPIResponse> {
    param.couponId = couponId;
    const deleteCouponKeywordResult = await this.apollo.mutate({
      mutation: DELETE_COUPON_KEYWORD_MUTATION,
      variables: {
        param: param,
      },
    });
    return deleteCouponKeywordResult.data.deleteCouponKeyword;
  }
}
